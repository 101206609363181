import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { DeckSettingsWrapper } from '@seedlang/hoc';
import { DeckUI } from '@seedlang/state';
import { AppUI } from '@seedlang/state';
import { AlertIcon } from '@seedlang/icons';
import autobind from 'autobind-decorator';
import Toggle from 'components/toggle';
import Text from 'components/text';
import styled from '@emotion/styled';
import Alert from 'components/alert';
import { Theme } from '@seedlang/constants';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceText from 'components/form/in_place_text';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  color: ${props => props.color};
  padding: ${props => props.padding};
  height: 100%;
  text-align: left;
  overflow: auto;
  select {
    color: #333;
  }
  label {
    font-size: 16px;
    line-height: 22px;
  }
  .toggle-true {
    .react-toggle-track {
      background: ${Theme.green};
    }
  }
  .toggle-false {
    .react-toggle-track {
      background: #b7b7b7;
    }
  }
  .react-toggle-thumb {
    border: none;
  }
  .toggle-component {
    margin-left: 0;
  }
  .rounded-button {
    margin-top: 0;
    &.disabled {
      color: #888;
    }
  }
  .icon-wrapper {
    display: inline-block;
    margin-right: 10px;
    svg {
      width: 22px;
      fill: #FFF;
    }
  }
  .description {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #CCC;
`;

const LabelAndDescription = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;


const Category = styled.div`

`;

const Title = styled.div`
  font-size: 12px;
  color: #333;
  margin-bottom: 10px;
  border-bottom: 1px solid #CCC;
  width: 100%;
  font-weight: bold;
  background: #CCC;
  padding: 5px;
  text-transform: uppercase;
  margin-top: ${props => props.marginTop};
`;

const InputWrapper = styled.div`
  width: 70px;
  padding-right: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

@observer
class DeckSettings extends React.Component {

  static defaultProps = {
    padding: "20px",
    background: "#545454",
    color: "#333",
  }

  @observable showUpdateNotice = false;
  @observable updatedTextInputs = {};


  @autobind afterUpdate(resp) {
    AppUI.loadUser(this.afterLoadUser);
    this.showUpdateNotice = true;
  }

  @autobind afterLoadUser(resp) {
    AppUI.setAuthUserStoreShowData(resp);
    if (AppUI.user.blockMediaAccess && DeckUI.mediaStore && DeckUI.mediaStore.clearAudioStream) {
      DeckUI.mediaStore.clearAudioStream();
    }
    setTimeout(() => this.showUpdateNotice = false, 6000);
  }

  @autobind onToggle(field, callback) {
    let data = {};
    data[field] = !AppUI.user[field];
    AppUI.user.set(field, data[field]);
    if (callback) {
      callback(data[field]);
    }
    AppUI.authUserStore.update({data: data}, this.afterUpdate);
  }

  @autobind onSelect(field, value) {
    let data = {};
    data[field] = value;
    AppUI.user.set(field, data[field]);
    AppUI.authUserStore.update({data: data}, this.afterUpdate);
  }

  @autobind onTextInputBlur(value, field) {
    let data = {};
    data[field] = value;
    AppUI.user.set(field, data[field]);
    AppUI.authUserStore.update({data: data}, this.afterUpdate);
    this.updatedTextInputs[field] = true;
    setTimeout(() => this.updatedTextInputs[field] = false, 5000);
  }

  render() {
    return (
      <Wrapper
        padding={this.props.padding}
        background={this.props.background}
        color={this.props.color}
      >
        {
          !this.showUpdateNotice && !this.props.hideTitle &&
            <Text
              heading="2"
              textAlign="center"
              margin="10px 0 20px 0"
              color={this.props.color}
            >
              Settings
            </Text>
        }
        {
          this.showUpdateNotice &&
            <Alert
              textAlign="center"
            >
              Your settings have changed.
            </Alert>
        }
        <div className='fields'>
          {
            this.props.categories.map((category, index) => {
              if (!category.hide) {
                return (
                  <Category
                    key={category.title}
                  >
                    <Title
                      marginTop={index > 0 ? "30px" : "0px"}
                      color={this.props.titleColor}
                    >
                      {category.title}
                    </Title>
                    {
                      category.settings.map((setting) => {
                        if (!setting.hide && !setting.androidOnly) {
                          return (
                            <Row
                              key={setting.name}
                            >
                              <LabelAndDescription>
                                <TextWrapper
                                  onClick={() => this.props.showDescription(setting.name)}
                                >
                                  <Text
                                    color={this.props.color}
                                    margin="0 0 0 5px"
                                    textAlign="left"
                                    fontSize="14px"
                                  >
                                    {setting.label}
                                  </Text>
                                </TextWrapper>
                                {
                                  isPresent(setting.description) && this.props.showDescriptions.indexOf(setting.name) !== -1 &&
                                    <Text
                                      color={this.props.color}
                                      margin="5px 5px 0 5px"
                                      fontSize="13px"
                                      lineHeight="17px"
                                      italic
                                    >
                                      {setting.description}
                                    </Text>
                                }
                                {
                                  setting.alerts && setting.alerts.map((alert) => {
                                    if (alert.show) {
                                      return (
                                        <Alert
                                          margin="10px 0 0 0"
                                          key={alert.slug}
                                        >
                                          <AlertIcon />
                                          {alert.message}
                                        </Alert>
                                      )
                                    } else {
                                      return null;
                                    }
                                  })
                                }
                              </LabelAndDescription>
                              {
                                setting.type === 'select' &&
                                  <InPlaceSelect
                                    allowUpdate
                                    value={AppUI.user[setting.name]}
                                    onChange={(val) => this.onSelect(setting.name, val)}
                                    options={setting.options}
                                  />
                              }
                              {
                                setting.type !== 'select' && setting.type !== 'number' &&
                                  <Toggle
                                    margin="0"
                                    selected={setting.toggleShowsOppositeValue ? !AppUI.user[setting.name] : AppUI.user[setting.name]}
                                    onToggle={() => this.onToggle(setting.name, setting.onToggle)}
                                  />
                              }
                              {
                                setting.type === 'number' &&
                                  <InputWrapper>
                                    <InPlaceText
                                      allowUpdate
                                      show
                                      number
                                      submitOnEnter
                                      onBlur={this.onTextInputBlur}
                                      onSubmit={this.onTextInputBlur}
                                      defaultValue={AppUI.user[setting.name]}
                                      model="users"
                                      field={setting.name}
                                      id={AppUI.user.id}
                                      margin="10px 0 10px 0"
                                    />
                                    {
                                      this.updatedTextInputs[setting.name] &&
                                        <Alert
                                          fontSize="10px"
                                          padding="5px"
                                        >
                                          Updated!
                                        </Alert>
                                    }
                                  </InputWrapper>
                              }
                            </Row>
                          )
                        } else {
                          return null;
                        }
                      })
                    }
                  </Category>
                )
              } else {
                return null;
              }
            })
          }
        </div>
      </Wrapper>
    );
  }
}

export default DeckSettingsWrapper(DeckSettings);
