import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import InPlaceSelect from 'components/form/in_place_select';
import { DeckStore, ExerciseStore, ExerciseTypeStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import { isBlank } from '@seedlang/utils';
import cx from 'classnames';
import Modal from 'components/modal';
import RadioButton from "components/radio_button";
import { AppUI } from "@seedlang/state";
import ReactTooltip from 'react-tooltip';
import styled from "@emotion/styled";
import Text from 'components/text';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  margin-bottom: 5px;
  .fa {
    cursor: pointer;
  }
  .button-primary {
    margin-left: 5px;
  }
`;

const ImageWrapper = styled.div`
  margin-left: 10px;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
`;

@observer
class ExerciseCreate extends React.Component {
  @observable exerciseTypeId;
  @observable showModal = false;

  constructor(props) {
    super(props);
    ExerciseTypeStore.getIndex({sort: 'name'});
  }

  @autobind onChangeExerciseType(id) {
    this.exerciseTypeId = id;
  }

  @autobind onClick() {
    if (this.exerciseTypeId) {
      ExerciseStore.create(
        {
          data: {
            name: this.refs.name && this.refs.name.value,
            exercise_type_id: this.exerciseTypeId,
            worksheet_id: this.props.worksheetId,
            deck_id: this.props.deckId,
          },
        },
        this.props.afterCreate
      );
      if (this.refs.name) {
        this.refs.name.value = '';
      }
      this.showModal = false;
    }
  }

  @autobind onClearExercises() {
    DeckStore.clearExercises({ids: {deckId: this.props.deckId}}, this.props.afterCreate);
  }

  render() {
    return (
      <Wrapper
        justifyContent={isBlank(this.props.deckId) ? 'space-between' : 'normal'}
      >
        {
          this.showModal && this.props.withModal &&
            <Modal
              onCloseModal={() => this.showModal = false}
              height={AppUI.layout.viewportHeight*0.8}
            >
              <fieldset>
                <legend>Exercise types</legend>
                <div className="col-xs-12">
                  {
                    ExerciseTypeStore.hasIndexData && ExerciseTypeStore.indexData.map(item => {
                      return (
                        <div key={item.id} style={{margin: "0 0 20px 0"}}>
                          <div style={{display: 'flex', marginBottom: '5px'}}>
                            <RadioButton
                              margin="0 5px 0 0"
                              value={this.exerciseTypeId === item.id}
                              onClick={() => this.onChangeExerciseType(item.id)}
                            />
                            {item.name}
                          </div>
                          <Text fontSize='12px' margin='0 5px 10px 5px'>{item.description}</Text>
                          <ImageWrapper>
                            <img alt='' src={`/images/exercise_types/${item.slug}.png`} width="100%" />
                          </ImageWrapper>
                        </div>
                      )
                    })
                  }
                </div>
              </fieldset>
              {
                this.exerciseTypeId &&
                  <div className="col-xs-2" style={{margin: '20px 0'}}>
                    <button
                      className={cx("button-primary", {
                        disabled: isBlank(this.exerciseTypeId),
                      })}
                      onClick={this.onClick}
                    >
                      Create exercise
                    </button>
                  </div>
              }
            </Modal>
        }
        {
          !this.props.hideName &&
            <input
              placeholder="Enter Exercise Name"
              maxLength={100}
              ref="name"
              style={{maxWidth: "35%"}}
            />
        }
        <InPlaceSelect
          includeBlank
          onChange={this.onChangeExerciseType}
          options={ExerciseTypeStore.indexData.filter(item => this.props.filterExerciseOptions ? this.props.filterExerciseOptions.indexOf(item.slug) !== -1 : true).map(item => [item.id, item.name])}
          value={this.exerciseTypeId}
        />
        {
          isBlank(this.props.deckId) &&
            <div
              data-tip
              data-for="exercise_types"
              onClick={() => this.showModal = true}
            >
              <i className='fa fa-eye' />
              <ReactTooltip
                place="top"
                type="dark"
                effect="solid"
                id="exercise_types"
                class="custom-tooltip"
              >
                See exercise types
              </ReactTooltip>
            </div>
        }
        <button
          className={cx("button-primary", {
            disabled: isBlank(this.exerciseTypeId),
          })}
          onClick={this.onClick}
        >
          Create
        </button>
        <button
          className="button-primary"
          onClick={this.onClearExercises}
        >
          <i className='fa fa-times' />
          Clear All
        </button>
      </Wrapper>
    );
  }
}

export default ExerciseCreate;
