import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import cx from 'classnames';
import { toJS } from 'mobx';
import { isEmpty, isFunction, filter, find } from 'lodash';
import { isBlank, isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  position: relative;
  border: ${(props) => props.border};
`;

const SearchResults = styled.ul`
  position: absolute;
  top: 42px;
  left: 0;
  background: white;
  width: 300px;
  border: 1px solid #bdbdbd;
  z-index: ${Theme.z["foreground"]};
  li {
    padding: 3px 10px;
    font-size: 14px;
    cursor: pointer;
    margin: 0;
  }
  li.selected {
    background: whitesmoke;
  }
  li:not(:last-child) {
    border-bottom: 1px solid #bdbdbd;
  }
  li:hover {
    background: whitesmoke;
  }
`;

const ESCAPE_KEYCODE = 27;
const ENTER_KEYCODE = 13;
const DOWN_KEYCODE = 40;
const UP_KEYCODE = 38;

@observer
class TypeAhead extends React.Component {
  @observable position = -1;
  @observable selectedOptions = [];
  @observable lastTextEntry;
  @observable _mounted = true;
  @observable focused = false;

  static defaultProps = {
    border: "1px solid #bdbdbd",
  }

  componentDidMount () {
    if (this.props.defaultValue) {
      this.onFocus();
      this.refs.input.value = this.props.defaultValue;
      this.lastTextEntry = this.props.defaultValue;
      this.performSearch();
    }
  }

  @computed get hasOptions() {
    return isPresent(toJS(this.options));
  }

  @computed get options() {
    if (this.props.localFilter) {
      return this.selectedOptions;
    }
    return this.props.options;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  @autobind onFocus() {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
    this.focused = true;
  }

  @autobind onBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
    if (this.props.enterOnTab) {
      this.onSelectCurrent();
    } else if (!this.props.keepInputOnBlur) {
      this.interval = setInterval(this.resetInput, 1100);
    }
  }

  @autobind onKeyUp(e) {
    if (e.keyCode === ESCAPE_KEYCODE) {
      this.props.onClear();
    } else if (e.keyCode === ENTER_KEYCODE) {
      this.onSelectCurrent();
    } else if (e.keyCode === DOWN_KEYCODE) {
      this.onIncrementPosition();
    } else if (e.keyCode === UP_KEYCODE) {
      this.onDecrementPosition();
    } else if (this.props.localFilter) {
      this.filter(this.refs.input.value);
    } else {
      this.lastTextEntry = this.refs.input.value;
      clearInterval(this.interval);
      this.interval = setTimeout(this.performSearch, 100)
    }
  }

  @autobind performSearch() {
    this.props.onKeyUp(this.lastTextEntry);
  }

  @autobind onSelectCurrent() {
    console.log("onSelectCurrent");
    if (this.props.onCreateValue && isFunction(this.props.onCreateValue) && (this.position === -1 || isEmpty(this.options))) {
      this.props.onCreateValue(this.refs.input.value);
    } else {
  	  this.props.onSelect(toJS(this.options)[this.position]);
    }
    if (!this.props.keepInputOnSelect) {
      this.resetInput();
    }
  }

  @autobind onSelect(id) {
    const result = find(toJS(this.options), { id: id });
    this.props.onSelect(result);
    if (!this.props.keepInputOnSelect) {
      this.resetInput();
    }
  }

  @autobind onIncrementPosition(e) {
    if (this.position < this.options.length - 1) {
      this.position = this.position + 1;
    }
  }

  @autobind onDecrementPosition() {
    if (this.position >= 0) {
      this.position = this.position - 1;
    }
  }

  @autobind resetInput() {
    if (this._mounted) {
      this.focused = false;
      clearInterval(this.interval);
      this.position = -1;
      this.selectedOptions = [];
      this.refs.input.value = '';
      if (this.props.onClear) {
        this.props.onClear();
      }
    }
  }

  filter(value) {
    if (isBlank(value)) {
      this.selectedOptions = [];
    } else {
      this.selectedOptions = filter(this.props.options, item => item[this.props.displayField] && item[this.props.displayField].match(value));
    }
  }

  render() {
    return (
      <Wrapper>
        <input
          ref="input"
          type="text"
          onKeyUp={this.onKeyUp}
          placeholder={this.props.placeholder}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          height={this.props.height}
          style={{border: this.props.border}}
        />
        {
          this.focused && this.hasOptions &&
            <SearchResults>
              {
                this.options.map((item, index) => {
                  if (!this.props.excludeIds || this.props.excludeIds.indexOf(item.id) === -1) {
                    return (
                      <li
                        key={index}
                        className={cx({ selected: index === this.position })}
                        onClick={this.onSelect.bind(this, item.id)}
                      >
                        {
                          isFunction(item[this.props.displayField]) && item[this.props.displayField]()
                        }
                        {
                          !isFunction(item[this.props.displayField]) && item[this.props.displayField]
                        }
                      </li>
                    );
                  } else {
                    return null;
                  }
                })
              }
            </SearchResults>
        }
      </Wrapper>
    );
  }
}

export default TypeAhead;
