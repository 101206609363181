import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import Text from 'components/text';
import SlideWrapper from 'components/hoc/slide_wrapper.js';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import Spinner from 'components/spinner';
import { AppUI } from '@seedlang/state';
import VideoPlayer from 'components/media/video_player';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  height: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  flex: 1;
  ${flexCenterColumn()}
`;

const CariWrapper = styled.div`
  position: relative;
  height: 200px;
  width: 200px;
  border: 10px solid #FFF;
  overflow: hidden;
  border-radius: 100px;
  background: #1db5d7;
  img {
    margin-top: -11px;
    width: 260px;
    margin-left: -43px;
  }
`;

@observer
class OnboardingIntro extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.showSpinner &&
            <Spinner
              background="#FFF"
            />
        }
        {
          !this.props.showSpinner &&
            <Content>
              <CariWrapper>
                <VideoPlayer
                  autoPlay
                  hideIcon
                  hideOverlay
                  loop={false}
                  position="relative"
                  borderRadius="50%"
                  videoClip={{mp4Url: '/video/hello010.mp4'}}
                  width="180px"
                />
              </CariWrapper>
              <Text
                fontSize={AppUI.layout.isMobile ? "30px" : "40px"}
                color="#0f6173"
                bold
                center
                letterSpacing="-1px"
                margin="20px 0 0 0"
              >
                WILLKOMMEN!
              </Text>
              <Text
                fontSize={AppUI.layout.isMobile ? "14px" : "16px"}
                lineHeight={AppUI.layout.isMobile ? "15px" : "18px"}
                margin="20px 0 0 0"
                center
                bold
                color="#FFF"
              >
                We just have few quick questions <br />to ask before getting started.
              </Text>
            </Content>
        }
        {
          !this.props.showSpinner &&
            <Button
              width="calc(100% - 20px)"
              height="50px"
              borderRadius="50px"
              scaleOnHover={1.05}
              margin="0 0 20px 0"
              backgroundOnHover="#FFF"
              background="#FFF"
              color={Theme.blue}
              onClick={this.props.onSubmitIntro}
            >
              Let's Go!
            </Button>
        }
      </Wrapper>
    );
  }
}

export default SlideWrapper(OnboardingIntro);
