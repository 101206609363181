import React from 'react';
import { observer } from 'mobx-react';
import { ReviewDeckOptionsWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import { formatNumber, isBlank, isPresent } from '@seedlang/utils';
import Alert from 'components/alert';
import Spinner from 'components/spinner';
import Status from 'components/visualization/status';
import ReviewDeckNoCards from 'components/review/review_deck_no_cards';
import ReviewDeckSettings from 'components/review/review_deck_settings';
import AdvancedFilters from "components/advanced_filters";

const Wrapper = styled.div`

`;

const ButtonText = styled.div`
  width: 280px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color};
  .fa-arrow-right {
    transform: rotate(-45deg);
  }
  i {
    position: absolute;
    left: -5px;
    top: 16px;
    color: #FFF;
  }
`;

const DeckOptions = styled.div`
  width: 100%;
`;

const CardsCount = styled.div`
  margin: 10px 0 0 0;
  color: #333;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-weight: bold;
`;

const StatusBar = styled.div`
  display: flex;
  justify-content: center;
`;

const StatusAlert = styled.div`
  position: relative;
  background: ${props => props.background};
  padding: 8px 25px 8px 15px;
  border-radius: 5px;
  color: #FFF;
  line-height: 17px;
  font-size: 13px;
  margin-top: 10px;
  .fa-exclamation-triangle {
    margin: 2px 2px 0 0;
  }
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 7px;
  right: 10px;
  cursor: pointer;
`;

const DailyGoalMessage = styled.div`
  margin: 0 5px;
  cursor: pointer;
`;

@observer
class ReviewDeckOptions extends React.Component {

  render () {
    return (
      <Wrapper>
        {
          this.props.showDeckUpdatedMessage &&
            <Alert
              width="100%"
              background={Theme.green}
              textAlign="center"
            >
              This deck was updated
            </Alert>
        }
        {
          this.props.showDailyGoalMessage &&
            <Alert
              width="100%"
              background={Theme.orange}
              textAlign="center"
              showCloseButton
              onClose={this.props.onCloseDailyGoalMessage}
            >
              <DailyGoalMessage
                onClick={this.props.onToggleEdit}
              >
                Having trouble reviewing all past due reviews? Set yourself a daily goal instead.
              </DailyGoalMessage>
            </Alert>
        }
        {
          !this.props.askForDeleteConfirmation && this.props.edit &&
            <ReviewDeckSettings
              deck={this.props.deck}
              closeOptionsAndUpdate={this.props.closeOptionsAndUpdate}
              showDailyGoalMessage={this.props.showDailyGoalMessage}
              refreshSelectedDeck={this.props.refreshSelectedDeck}
              hideTitle
              padding={0}
            />
        }
        {
          this.props.deck.reviewCardsCount === 0 && !this.props.edit && !this.props.askForDeleteConfirmation &&
            <ReviewDeckNoCards />
        }
        {
          this.props.deck.reviewCardsCount > 0 && !this.props.edit &&
            <DeckOptions>
              <StatusBar>
                {
                  !this.props.deck.useDailyGoal &&
                    <Status
                      onClick={() => this.props.onClickStatus('past-due')}
                      mobile
                      cursor="pointer"
                      margin="0 5px"
                      text="Past Due"
                      showDash={isBlank(this.props.cardsCount)}
                      color={this.props.due === 'past-due' ? Theme.orange : "#CCC"}
                      count={this.props.pastDueCount}
                    />
                }
                {
                  this.props.deck.useDailyGoal &&
                    <Status
                      onClick={() => this.props.onClickStatus('past-due')}
                      mobile
                      cursor="pointer"
                      margin="0 5px"
                      text="Daily Goal"
                      showDash={isBlank(this.props.deck.remainingDailyGoal)}
                      color={this.props.due === 'past-due' ? Theme.orange : "#CCC"}
                      count={this.props.deck.remainingDailyGoal}
                    />
                }
                <Status
                  onClick={() => this.props.onClickStatus('caught-up')}
                  mobile
                  cursor="pointer"
                  margin="0 5px"
                  text="Caught Up"
                  showDash={isBlank(this.props.cardsCount)}
                  color={this.props.due === 'caught-up' ? Theme.green : "#CCC"}
                  count={this.props.caughtUpCount}
                />
                <Status
                  onClick={() => this.props.onClickStatus('retired')}
                  mobile
                  cursor="pointer"
                  margin="0 5px"
                  text="Retired"
                  showDash={isBlank(this.props.cardsCount)}
                  color={this.props.due === 'retired' ? Theme.purple : "#CCC"}
                  count={this.props.retiredCount}
                />
              </StatusBar>
              {
                isPresent(this.props.showStatusAlert) &&
                  <StatusAlert
                    background={this.props.showStatusAlert === 'caught-up' ? Theme.green : Theme.purple}
                  >
                    <i className='fa fa-exclamation-triangle' />
                    {
                      this.props.showStatusAlert === 'caught-up' &&
                        <span>This will only show "Caught Up" Cards. These are cards that you have recently seen, and are not yet due to be reviewed again.</span>
                    }
                    {
                      this.props.showStatusAlert === 'retired' &&
                        <span>This will only show "Retired" Cards. These are cards that you have already seen enough and are considered to be learned.</span>
                    }
                    <CloseIconWrapper
                      onClick={() => this.props.closeStatusAlert(this.props.showStatusAlert)}
                    >
                      <i className='fa fa-times' />
                    </CloseIconWrapper>
                  </StatusAlert>
              }
              <AdvancedFilters
                filterSettings={this.props.webFilterSettings}
                margin="10px 0"
              />
              {
                isPresent(this.props.cardsCount) && this.props.hasAdvancedFilterSelected &&
                  <CardsCount>
                    { `${formatNumber(this.props.cardsCount)} Cards Match these Filters` }
                  </CardsCount>
              }
              {
                isPresent(this.props.cardsCount) && !this.props.hasAdvancedFilterSelected &&
                  <CardsCount>
                    { `${formatNumber(this.props.cardsCount)} ${this.props.cardsText} Cards Match these Filters` }
                  </CardsCount>
              }
              {
                isBlank(this.props.cardsCount) &&
                  <CardsCount>
                    <Spinner
                      margin="0"
                      background={Theme.blue}
                    />
                  </CardsCount>
              }
              <Button
                onClick={this.props.onOpenDeck}
                height="50px"
                width="100%"
                margin="10px 0 0 0"
                background={this.props.enabledOpenDeck ?  Theme.blue : "#CCC"}
                cursor={this.props.enabledOpenDeck ? "pointer" : "default"}
              >
                <ButtonText
                  color="#FFF"
                >
                  <i className='fa fa-arrow-right' />
                  Open Deck
                </ButtonText>
              </Button>
            </DeckOptions>
        }
      </Wrapper>
    );
  }
}

export default ReviewDeckOptionsWrapper(ReviewDeckOptions);
