"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = formatFilterTag;
var _is_present = _interopRequireDefault(require("./is_present"));
var _is_blank = _interopRequireDefault(require("./is_blank"));
var _lodash = require("lodash");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function formatFilterTag(filter, value) {
  var displayValue;
  if (filter.multiSelect && (0, _is_present["default"])(value)) {
    if ((0, _lodash.isArray)(value)) {
      var ids = value.filter(function (item) {
        return (0, _is_present["default"])(item);
      });
      if (ids.length > 2) {
        displayValue = "".concat(ids.length, " Selected");
      } else {
        displayValue = ids.map(function (id) {
          var option = filter.options.find(function (option) {
            return option[0] === id;
          });
          if (option) {
            return option[1];
          }
        }).sort().join(", ");
      }
    } else if (value.match(",")) {
      var _ids = value.split(",").filter(function (item) {
        return (0, _is_present["default"])(item);
      });
      if (_ids.length > 2) {
        displayValue = "".concat(_ids.length, " Selected");
      } else {
        displayValue = _ids.map(function (id) {
          var option = filter.options.find(function (option) {
            return option[0] === id;
          });
          if (option) {
            return option[1];
          }
        }).sort().join(", ");
      }
    }
  }
  if ((0, _is_blank["default"])(displayValue) && (0, _is_present["default"])(value)) {
    var option = filter.options.find(function (item) {
      return item[0] === value;
    });
    if ((0, _is_present["default"])(option)) {
      displayValue = option[1];
    }
  } else if (filter.userKey === 'vocabSeen') {
    displayValue = 'Any';
  }
  return (0, _is_present["default"])(displayValue) ? "".concat(filter.label, ": ").concat(displayValue) : null;
}