"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var GREEN = '#13ab5e';
var RED = '#FF5252';
var Theme = {
  colorForState: {
    success: GREEN,
    failure: RED
  },
  blue: '#1DB5D7',
  darkBlue: '#1190ad',
  darkestBlue: '#254F6A',
  blueGray: '#1AA3C2',
  blueFaded: '#A0D3DE',
  blueText: '#e2eef1',
  lightBlue: '#dff9ff',
  blueGreen: '#26566b',
  brightBlue: '#00aaf5',
  neonBlue: '#08fff4',
  successCheckColor: '#72ec21',
  siteHeaderColor: '#14a9cc',
  headerColor: '#6b6767',
  secondaryColor: 'blue',
  green: GREEN,
  fadedGreen: 'rgba(34, 171, 28, 0.32)',
  darkGreen: '#15945b',
  mediumGreen: '#298058',
  darkestGreen: '#145638',
  neonGreen: '#21ec08',
  successGreen: 'green',
  gray: '#7D7D7D',
  lightGray: '#efefef',
  orange: '#f78139',
  darkOrange: 'rgb(214, 127, 18)',
  accentOrange: '#ef970c',
  yellow: 'rgb(239, 239, 63)',
  paleYellow: 'rgba(255, 255, 0, 0.18)',
  dirtyYellow: '#c7c709',
  gold: '#F8C749',
  red: RED,
  darkRed: '#b31212',
  darkestRed: '#b31212',
  purple: '#9873E6',
  neonPurple: 'rgba(196, 66, 220, 1)',
  darkPurple: '#7a0f8e',
  disabled: '#e4e4e4',
  footerColor: "#25343e",
  z: {
    "base": 0,
    "supplement-maximized": 1,
    "background": 2,
    "foreground": 3,
    "over-foreground": 4,
    "over-over-foreground": 5,
    "video-overlay-video": 81,
    "video-overlay-foreground": 82,
    "video-overlay-error": 83,
    "card-1": 90,
    "card-2": 91,
    "card-3": 92,
    "card-4": 93,
    "supplement": 90,
    "supplement-overlay-1": 94,
    "supplement-overlay-2": 95,
    "popup-background": 100,
    "popup-foreground": 101,
    "card-info-1": 110,
    "card-info-2": 111,
    "card-info-3": 112,
    "card-info-4": 113,
    "topmost-under-overlay": 199,
    "overlay": 200,
    "overlay-1": 201,
    "overlay-2": 202,
    "overlay-3": 203,
    "modal-background": 1000,
    "modal-foreground": 1001,
    "dropdown-background": 1004,
    "dropdown-foreground": 1004,
    "sidebar-background": 1010,
    "sidebar-foreground": 1011,
    "topmost": 1099,
    "topmost-1": 1100
  }
};
var _default = Theme;
exports["default"] = _default;