"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = setCookieValue;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _is_present = _interopRequireDefault(require("./is_present"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function setCookieValue(name, value) {
  if ((0, _is_present["default"])(value)) {
    _jsCookie["default"].set(name, value, {
      expires: 999,
      path: '/'
    });
  } else {
    _jsCookie["default"].remove(name, {
      expires: 999,
      path: '/'
    });
  }
}