import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import autobind from 'autobind-decorator';
import { ExerciseEntryStore } from '@seedlang/stores';
import Spinner from 'components/spinner';
import RadioButton from 'components/radio_button';
import cx from 'classnames';
import { AppUI } from '@seedlang/state';
import InPlaceText from "components/form/in_place_text";

@observer
class ExerciseEntryCreate extends React.Component {
  @observable source1;
  @observable target1;
  @observable prompt;
  @observable showSpinner = false;
  @observable showErrorMessage = false;
  @observable firstWordIsLowercase;
  @observable showSource = false;
  @observable word;
  @observable showGrammarTip = false;
  @observable grammarTip;
  @observable answers = ['','','',''];

  constructor (props) {
    super();
  }

  @computed get sentenceOrWord() {
    // change later
    if (['Sentence', 'Match Text'].includes(this.props.exercise.exerciseType.name)) {
      return 'Sentence';
    }
    return 'Sentence or Word';
  }

  @autobind onSubmit() {
    this.showErrorMessage = false;
    this.showSpinner = true;
    ExerciseEntryStore.create({data: {
      exercise_id: this.props.exercise.id,
      source1: this.source1,
      target1: this.target1,
      prompt: this.prompt,
      grammarTip: this.grammarTip,
      first_word_is_lowercase: this.firstWordIsLowercase,
      language_id: this.props.languageId,
      show_source: this.showSource,
      answers: this.props.exercise.exerciseType.slug === 'multipleChoice' ? this.answers : null,
      }}, this.afterCreate);

    this.source1 = null;
    this.target1 = null;
    this.prompt = null;
    this.grammarTip = null;
    this.firstWordIsLowercase = undefined;
    this.showSource = false;
    this.showGrammarTip = false;
    this.answers = ['','','',''];
  }

  @autobind afterCreate(resp) {
    if (!resp.success) {
      this.showErrorMessage = true;
    }
    this.showSpinner = false;
    this.props.afterCreate();
  }

  @autobind updateTargetText() {
    this.target1 = this.refs.target1.value;
  }

  render() {
    return (
      <div className='exercise-entry-create'>
        <fieldset>
          <legend>Add Entry</legend>
          {
            this.showErrorMessage &&
              <div className='row'>
                <div className='col-xs-12'>
                  <div className='alert'>
                    There was an error.
                  </div>
                </div>
              </div>
          }
          <div className='row'>
            <div className='col-xs-12'>
              <input
                type="text"
                ref="prompt"
                value={this.prompt || ''}
                onChange={() => this.prompt = this.refs.prompt.value}
                placeholder={`Add a Prompt${this.props.exercise.exerciseType.slug === 'multipleChoice' ? ' (optional)' : ''}`}
              />
            </div>
          </div>
          {
            this.props.exercise.exerciseType && !this.props.exercise.exerciseType.hideTarget &&
              <div className='row'>
                <div className='col-xs-12'>
                  <textarea
                    type="text"
                    ref="target1"
                    value={this.target1 || ''}
                    onChange={this.updateTargetText}
                    placeholder={`Target ${this.sentenceOrWord}`}
                  />
                </div>
              </div>
          }
          {
            this.props.exercise.exerciseType && !this.props.exercise.exerciseType.hideSource && this.showSource &&
              <div className='row'>
                <div className='col-xs-12'>
                  <textarea
                    type="text"
                    ref="source1"
                    value={this.source1 || ''}
                    onChange={() => this.source1 = this.refs.source1.value}
                    placeholder={`English ${this.sentenceOrWord}`}
                  />
                </div>
              </div>
          }
          {
            this.sentenceOrWord === 'Sentence' && AppUI.site.languageId !== 'AR' &&
              <div className='row'>
                <div
                    className='col-xs-12'
                    style={{display: 'flex', alignItems: 'center'}}
                >
                  <span>
                    1st word of the sentence is always capitalized (usually proper nouns{this.props.languageId === 'DE' ? ' and nouns' : ''}).
                  </span>
                  <RadioButton
                      onClick={() => this.firstWordIsLowercase = this.firstWordIsLowercase === false ? undefined : false}
                      value={this.firstWordIsLowercase === false}
                      height="12px"
                      width="12px"
                      margin="3px 3px 3px 20px"
                  />
                  <span>
                    Yes
                  </span>
                  <RadioButton
                      onClick={() => this.firstWordIsLowercase = this.firstWordIsLowercase ? undefined : true}
                      value={this.firstWordIsLowercase}
                      height="12px"
                      width="12px"
                      margin="3px 3px 3px 7px"
                  />
                  <span>
                    No
                  </span>
                </div>
              </div>
          }
          {
            this.props.exercise.exerciseType.slug === 'multipleChoice' &&
              <div className='col-xs-12' style={{display: 'flex'}}>
                {
                  this.answers.map((item,index) => {
                    return (
                      <input
                        type="text"
                        key={`answer-${index}`}
                        ref={`answer-${index}`}
                        value={item}
                        onChange={() => this.answers[index] = this.refs[`answer-${index}`].value}
                        placeholder="Add an answer"
                      />
                    );
                  })
                }
              </div>
          }
          {
            this.props.exercise.exerciseType.slug === 'reorder' && !AppUI.site.languageId?.startsWith('EN') &&
              <div className='row'>
                <div
                    className='col-xs-12'
                    style={{display: 'flex', alignItems: 'center'}}
                >
                  <span>
                    The prompt is the English translation of the target.
                  </span>
                  <RadioButton
                      onClick={() => this.showSource = false}
                      value={!this.showSource}
                      height="12px"
                      width="12px"
                      margin="3px 3px 3px 20px"
                  />
                  <span>
                    Yes
                  </span>
                  <RadioButton
                      onClick={() => this.showSource = true}
                      value={this.showSource}
                      height="12px"
                      width="12px"
                      margin="3px 3px 3px 7px"
                  />
                  <span>
                    No
                  </span>
                </div>
              </div>
          }
          {
            this.props.showGrammarTip &&
              <div className='grammar-tip'>
                <span
                  onClick={() => this.showGrammarTip = !this.showGrammarTip}
                  style={{display: 'flex', alignItems: 'center', cursor: 'pointer', fontSize: '13px'}}
                >
                  <span
                    style={{marginRight: "5px"}}
                  >
                    <i className='fa fa-lightbulb-o' style={{fontSize: "16px"}}/>
                  </span>
                  Add a tip
                </span>
                {
                  this.showGrammarTip &&
                  <InPlaceText
                    show
                    smallRichText
                    noSaveButton
                    value={this.grammarTip || ''}
                    inputType="textarea"
                    ref="grammarTip"
                    onChange={() => this.grammarTip = this.refs.grammarTip.value}
                  />
                }
              </div>
          }
          <div className='row'>
            <div className="col-xs-12" style={{display: 'flex', alignItems: 'center'}}>
              {
                !this.showSpinner &&
                  <button
                    className={cx("button-primary", {
                      disabled: this.sentenceOrWord === 'Sentence' && this.firstWordIsLowercase === undefined && AppUI.site.languageId !== 'AR'
                    })}
                    onClick={this.onSubmit}
                    disabled={this.sentenceOrWord === 'Sentence' && this.firstWordIsLowercase === undefined && AppUI.site.languageId !== 'AR'}
                  >
                    Create
                  </button>
              }
              {
                this.showSpinner &&
                  <Spinner
                    style={{margin: 10}}
                    className='blue'
                  />
              }
              {
                (this.props.exercise.exerciseType.slug === 'multipleChoice' || this.props.exercise.exerciseType.slug === 'matchMedia') &&
                  <small style={{marginLeft: "10px"}}>Image(s) must be added after the entry is created.</small>
              }
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}

export default ExerciseEntryCreate;
