"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = isBlank;
var _isBlank = _interopRequireDefault(require("is-blank"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function isBlank(object) {
  if (object === true || object === false || object === 0) {
    return false;
  }
  return (0, _isBlank["default"])(object);
}
;