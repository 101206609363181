"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = isPresent;
var _is_blank = _interopRequireDefault(require("./is_blank"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function isPresent(object) {
  return !(0, _is_blank["default"])(object);
}
;