import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import uuid from 'uuid/v4';
import { isFunction } from "lodash";

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin};
  text-transform: none;
  color: ${(props) => props.color};
  cursor: ${props => isFunction(props.onClick) ? 'pointer' : 'default'};
`;

@observer
class InfoTooltip extends React.Component {

  constructor(props) {
    super(props)
    this.uuid = uuid();
  }

  static defaultProps = {
    icon: 'info-circle',
    color: 'black',
    position: 'top',
    margin: '0 5px',
  }

  render() {
    return (
      <Wrapper
        className='info-tooltip'
        data-tip
        data-for={this.uuid}
        margin={this.props.margin}
        color={this.props.color}
        onClick={this.props.onClick}
      >
        <i className={`fa fa-${this.props.icon}`} />
        <ReactTooltip
          place={this.props.position}
          type="dark"
          effect="solid"
          id={this.uuid}
          class="custom-tooltip"
        >
          {this.props.children}
        </ReactTooltip>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(InfoTooltip);
