import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { isBlank } from '@seedlang/utils';
import ReactTooltip from 'react-tooltip';
import autobind from "autobind-decorator";

const MultipleChoice = styled.div`
  width: ${props => props.width};
  background: ${props => props.background};
`;

const Row = styled.div`
  ${flexCenter()}
  border-bottom: ${props => props.lastRow ? '' : '1px solid #CCC'};
  padding: 5px 10px;
`;

const FlexExpand = styled.div`
  flex: 1;
  padding: 0 5px;
`;

const CheckboxWrapper = styled.div`
  ${flexCenter()}
  width: 30px;
`;

const Selected = styled.div`
  position: relative;
  width: 50px;
`;

@observer
class MultipleChoiceInput extends React.Component {

  static defaultProps = {
    background: 'whitesmoke',
    width: '500px',
  }

  @autobind afterChangeSourceText(multipleChoiceOption, value) {
    multipleChoiceOption.set('sourceText', value);
  }

  @autobind afterChangeTargetText(multipleChoiceOption, value) {
    multipleChoiceOption.set('targetText', value);
  }

  @autobind afterChangeCorrect(multipleChoiceOption, value) {
    if (value) {
      // each(multipleChoiceOptions, (item) => {  // deactivated to allow several correct answers (also in MultipleChoiceOption::UpdateService)
      //   item.set('correct', false);
      // })
      multipleChoiceOption.set('correct', true);
    } else {
      multipleChoiceOption.set('correct', false);
    }
  }

  render() {
    return (
      <MultipleChoice
        background={this.props.background}
        width={this.props.width}
      >
        {
          isBlank(this.props.multipleChoiceOptions.find(item => item.correct)) &&
          <i className="fa fa-warning fa-row-warning" />
        }
        {
          this.props.multipleChoiceOptions.map((item,index) => {
            return (
              <Row
                key={item.id}
                lastRow={index === this.props.multipleChoiceOptions.length-1}
              >
                <Selected
                  className='info-tooltip'
                  data-tip
                  data-for={this.uuid}
                >
                  {
                    (isBlank(item.targetText) || (isBlank(item.sourceText) && !this.props.hideEnglish)) &&
                      <i className='fa fa-warning' />
                  }
                  {item.selectedCount}
                  <ReactTooltip
                    type="dark"
                    effect="solid"
                    id={this.uuid}
                    class="custom-tooltip"
                  >
                    Number of times this answer was selected by users
                  </ReactTooltip>
                </Selected>
                <FlexExpand>
                  <InPlaceText
                    id={item.id}
                    submitOnEnter
                    defaultValue={item.targetText}
                    model="multiple_choice_options"
                    field="target_text"
                    editBeforeBlur={isBlank(item.targetText)}
                    placeholder="Enter Target"
                    afterChange={this.afterChangeTargetText.bind(this, item)}
                  />
                </FlexExpand>
                {
                  !this.props.hideEnglish &&
                    <FlexExpand>
                      <InPlaceText
                        id={item.id}
                        submitOnEnter
                        defaultValue={item.sourceText}
                        model="multiple_choice_options"
                        field="source_text"
                        editBeforeBlur={isBlank(item.sourceText)}
                        placeholder="Enter English"
                        afterChange={this.afterChangeSourceText.bind(this, item)}
                      />
                    </FlexExpand>
                }
                <CheckboxWrapper>
                  <InPlaceCheckbox
                    id={item.id}
                    value={item.correct}
                    model="multiple_choice_options"
                    field="correct"
                    icon="check"
                    afterChange={this.afterChangeCorrect.bind(this, item)}
                  />
                </CheckboxWrapper>
              </Row>
            )
          })
        }
      </MultipleChoice>
    );
  }
}

export default ErrorBoundary(MultipleChoiceInput);
