import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { isEmpty } from 'lodash';
import { isBlank, isPresent } from '@seedlang/utils';
import cx from 'classnames';
import { Api } from '@seedlang/stores';
import pluralize from 'pluralize';
import InPlaceTextInput from 'components/form/in_place_text_input';
import autobind from 'autobind-decorator';
import Uuid from 'uuid/v4';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import TextDisplay from 'components/text_display';
import Alert from 'components/alert';
import { Editor } from '@tinymce/tinymce-react';
import { AppUI } from "@seedlang/state";

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
  color: ${props => props.color};
  max-width: ${props => props.maxWidth};
`;

const InnerWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: ${props => props.fontSize};
  ${props => props.editable && editable()}
  ${props => props.number && number()}
  word-break: break-word;
  p {
    margin: 0 0 20px 0;
  }
  textarea, input, .text {
    width: 100%;
  }
  input {
    height: ${props => props.inputHeight};
    padding: ${props => props.padding};
    font-size: ${props => props.fontSize};
    direction: ${props => props.rtl ? 'rtl' : 'auto'};
    text-align: left;
  }
  textarea {
    height: ${props => props.height || "120px"};
    font-size: 14px;
    line-height: 18px;
  }
`;

const editable = function() {
  return `
    border: 1px solid transparent;
    display: inline-block;
    width: 100%;
    min-height: 20px;
    &:hover {
      border: 1px dotted gray;
    }
  `;
}

const number = function() {
  return `
    min-width: 40px;
    max-width: 70px;
    input {
      min-width: 40px;
      max-width: 70px;
    }
  `;
}

const ButtonWrapper = styled.div`
  position: absolute;
  top: -45px;
  right: 5px;
`;

const EditingText = styled.div`
  position: absolute;
  background: white;
  border: 1px solid gray;
  font-size: 12px;
  line-height: 12px;
  padding: 5px;
  z-index: 99;
`;

const EditorWrapper = styled.div`
  position: relative;
  margin-top: ${props => props.marginTop};
`;

@observer
class InPlaceText extends React.Component {
  @observable editSelected = false;
  @observable value = null;
  @observable lastValue = null;

  @computed get editMode() {
    return (AppUI.user.canUpdateRecords || this.props.allowUpdate) && (this.props.show || this.editSelected);
  }

  static propTypes = {
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    focusOnMount: PropTypes.bool,
    clearOnEnter: PropTypes.bool,
    editable: PropTypes.bool,
    afterChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    formattedValue: PropTypes.string,
    model: PropTypes.string,
    placeholder: PropTypes.string,
    field: PropTypes.string,
    inputType: PropTypes.string,
    icon: PropTypes.string,
    label: PropTypes.string,
    verb: PropTypes.string,
    data: PropTypes.object,
    show: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    suffix: PropTypes.string,
  }

  static defaultProps = {
    inputType: 'text',
    verb: 'PUT',
    show: false,
    fontSize: "15px",
    inputPadding: "0 5px",
    inputHeight: "24px",
    color: "#000",
  }

  constructor(props) {
    super(props);
    this.id = Uuid();
  }

  async componentDidMount() {
    if (this.props.focusOnMount && this.props.inputType === 'textarea' && this.refs.inputField) {
      this.refs.inputField.focus();
    }
    this.value = this.props.value || this.props.defaultValue;
    this.lastValue = this.value;
    if (this.props.editBeforeBlur) {
      this.editSelected = true;
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.value !== prevProps.value || this.props.defaultValue !== prevProps.defaultValue) && (!this.props.blockOverwrite || isBlank(this.value))) {
      this.value = this.props.value || this.props.defaultValue;
      this.lastValue = this.value;
    }
  }

  update() {
    if ((!this.props.rejectBlank || isPresent(this.value)) && (this.lastValue !== this.value)) {
      const data = { data: this.props.data || {} };
      data.data[this.props.field] = this.value;
      if (this.props.verb === 'PUT' && this.props.model) {
        Api.put({
          url: this.props.id ? `/api/${pluralize(this.props.model)}/${this.props.id}` : `/api/${this.props.model}`,
          data: data,
          successCallback: this.updateSuccess.bind(this),
        });
      } else if (this.props.model) {
        Api.post({
          url: this.props.id ? `/api/${pluralize(this.props.model)}` : `/api/${this.props.model}`,
          data: data,
          successCallback: this.updateSuccess.bind(this),
        });
      }
    }
  }

  updateSuccess(resp) {
    this.lastValue = this.value;
    if (this.props.updateCallback) {
      this.props.updateCallback(resp);
    }
    if (this.props.afterChange) {
      this.props.afterChange(this.value);
    }
  }

  @computed get richText() {
    return this.props.richText || this.props.smallRichText;
  }

  @autobind onEdit() {
    if (!this.props.blockClickToEdit) {
      this.editSelected = true;
      this.failure = null;
    }
  }

  @autobind onChange(value) {
    if (this.props.readOnly || (this.props.rejectBlank && isBlank(value))) {
      return;
    }
    if (isBlank(this.props.value) && this.props.inputType === 'textarea' && !this.richText) {
      this.value = this.refs.inputField.value;
    } else if (isBlank(this.props.value) || this.richText) {
      this.value = value;
    }
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  @autobind onSubmit() {
    if (!this.props.type || this.props.type !== 'number' || !this.props.max || (parseInt(this.value, 10) <= this.props.max)) {
      if (this.props.onSubmit) {
        this.props.onSubmit(this.value, this.props.field);
      } else {
        this.update();
      }
      if (!this.props.rejectBlank || isPresent(this.value)) {
        this.editSelected = false;
      }
    }
  }

  @autobind onFocus() {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  @autobind onBlur() {
    if (this.props.onBlur) {
      this.props.onBlur(this.value, this.props.field);
    }
    this.onSubmit()
  }

  @autobind onEditorChange (content, editor) {
    this.value = content;
    if (this.props.onChange) {
      this.props.onChange(content);
    }
  };

  _input() {
    if (this.props.inputType === 'textarea') {
      return (
        <textarea
          type="text"
          ref="inputField"
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          value={this.value || ''}
          onBlur={this.onBlur}
          onSubmit={this.onSubmit}
          style={this.props.style}
          maxLength={this.props.maxLength}
        />
      );
    }
    return (
      <InPlaceTextInput
        maxLength={this.props.maxLength}
        caretPosition={this.props.caretPosition}
        style={this.props.style}
        focusOnMount={this.props.focusOnMount}
        type={this.props.type}
        step={this.props.step}
        max={this.props.max}
        submitOnEnter={this.props.submitOnEnter}
        clearOnEnter={this.props.clearOnEnter}
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        value={this.value}
        onSubmit={this.onSubmit}
        readOnly={this.props.readOnly}
        rejectBlank={this.props.rejectBlank}
      />
    );
  }

  _content() {
    if (this.editMode) {
      return (
        <span>
          {!this.richText && this._input()}
          {
            this.props.editingText &&
              <EditingText>
                {this.props.editingText}
              </EditingText>
          }
        </span>
      )
    } else if (this.props.icon) {
      return (
        <i
          className={cx('fa', `fa-${this.props.icon}`, 'on-click', {
            disabled: isPresent(this.props.icon) && isEmpty(this.value)
          })}
        />
      );
    } else if (this.props.markdown && this.value) {
      return (
        <TextDisplay
          markdown={this.value}
          maxHeight={this.props.maxHeight}
          scrollable={this.props.scrollable}
        />
      );
    } else if (!this.richText && !this.props.markdown && this.props.formattedValue) {
      return <span dangerouslySetInnerHTML={{ __html: this.props.formattedValue }} />;
    } else if (this.props.newLineToBr && isPresent(this.value)) {
      const formatted = this.value.replace(/(?:\r\n|\r|\n)/g, '<br />');
      return <span dangerouslySetInnerHTML={{ __html: formatted }} />;
    } else if (this.props.type === 'number') {
      return this.value;
    } else if (!this.value && this.props.placeholder) {
      return <span style={{color: "gray"}} dangerouslySetInnerHTML={{ __html: this.props.placeholder }} />;
    }
    return (
      <TextDisplay
        htmlText={this.value}
        maxHeight={this.props.maxHeight}
        scrollable={this.props.scrollable}
      />
    );
  }

  render() {
    return (
      <Wrapper
        color={this.props.color}
        maxWidth={this.props.maxWidth}
      >
        <InnerWrapper
          number={this.props.type === 'number'}
          editable={!this.editMode && !this.props.hideEditable}
          onClick={this.onEdit}
          height={this.props.height ? this.props.height : this.props.markdown ? "400px" : "120px"}
          fontSize={this.props.fontSize}
          padding={this.props.inputPadding}
          inputHeight={this.props.inputHeight}
          rtl={this.props.rtl}
        >
          {
            this.props.label &&
              <label>
                {this.props.label}
              </label>
          }
          {this._content()}{this.props.suffix}
          {
            this.editMode && this.props.warning &&
              <Alert
                fontSize="12px"
                padding="2px"
                showCloseButton
                background="#f2dede"
                color="black"
              >
                {this.props.warning}
              </Alert>
          }
          {
            this.props.markdown && this.editMode &&
              <a href='https://markdown-it.github.io/' target='_blank' rel="noopener noreferrer" style={{fontSize: 12, marginTop: 10}}>Markdown Documentation</a>
          }
          {
            this.props.markdown && this.editMode &&
              <a href='https://tableconvert.com/' target='_blank' rel="noopener noreferrer" style={{fontSize: 12, marginTop: 10, marginLeft: 10}}>Table Converter</a>
          }
        </InnerWrapper>
        {
          this.editMode && this.richText &&
            <EditorWrapper
              marginTop={this.props.noSaveButton ? '0px' : '10px'}
            >
              {
                // if this stops working for a domain, you have to add the domain to the Tiny Cloud backend: https://www.tiny.cloud/my-account/domains/}
              }
              <Editor
                apiKey="9bllmtatv9ljyg658erbhlrc3zj5u7fjjx5iy1ksemj7ty0z"
                value={this.value}
                onEditorChange={this.onEditorChange}
                init={{
                  plugins: 'preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                  mobile: {
                    plugins: 'preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons'
                  },
                  menu: {
                    tc: {
                      title: 'Comments',
                      items: 'addcomment showcomments deleteallconversations'
                    }
                  },
                  theme_advanced_text_colors : "FF00FF,FFFF00,000000",
                  menubar: this.props.smallRichText ? '' : 'file edit view insert format tools table tc help',
                    toolbar: `undo redo | bold italic underline strikethrough | forecolor backcolor removeformat | ${AppUI.site.name === 'Seedlang' ? '' : 'fontfamily '}fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image link anchor codesample | a11ycheck ltr rtl | showcomments addcomment |`,
                    toolbar_sticky: true,
                    color_map: [
                      '#47A455', 'Seedlang Green',
                      '#FF914D', 'Seedlang Orange',
                      '#FF5252', 'Seedlang Red',
                      '#9873E6', 'Seedlang Purple',
                      '#1DB5D7', 'Seedlang Blue',

                      '#BFEDD2', 'Light Green',
                      '#FBEEB8', 'Light Yellow',
                      '#F8CAC6', 'Light Red',
                      '#ECCAFA', 'Light Purple',
                      '#C2E0F4', 'Light Blue',

                      '#169179', 'Dark Turquoise',
                      '#E67E23', 'Orange',
                      '#BA372A', 'Dark Red',
                      '#843FA1', 'Dark Purple',
                      '#236FA1', 'Dark Blue',

                      '#ECF0F1', 'Light Gray',
                      '#CED4D9', 'Medium Gray',
                      '#95A5A6', 'Gray',
                      '#7E8C8D', 'Dark Gray',
                      '#34495E', 'Navy Blue',

                      '#000000', 'Black',
                      '#ffffff', 'White',
                    ],
                  image_advtab: true,
                  link_list: [
                    { title: 'My page 1', value: 'https://www.tiny.cloud' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_list: [
                    { title: 'My page 1', value: 'https://www.tiny.cloud' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' }
                  ],
                  image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Some class', value: 'class-name' }
                  ],
                  importcss_append: true,
                  templates: [
                    { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                    { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                    { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                  ],
                  template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                  template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                  height: this.props.smallRichText ? 220 : 600,
                  image_caption: true,
                  quickbars_selection_toolbar: 'bold italic forecolor | quicklink h2 h3 | quicktable',
                  noneditable_class: 'mceNonEditable',
                  toolbar_mode: 'sliding',
                  spellchecker_ignore_list: ['Ephox', 'Moxiecode'],
                  content_style: '.mymention{ color: gray; }',
                  contextmenu: 'link image table',
                  table_header_type: 'sectionCells',
                  table_use_colgroups: false,
                  relative_urls: false,
                  convert_urls: false,
                  document_base_url: `https://${window.location.hostname}`,
                  invalid_styles: {
                      '*' : 'border border-width border-collapse border-style border-color border-bottom-width border-top-width border-right-width border-left-width',
                      'table': 'width height',
                      'tr' : 'width height',
                      'th' : 'width height',
                      'td' : 'width height',
                      'col' : 'width'
                    },
                  indent: false,
                }}
             />
              {
                !this.props.noSaveButton &&
                  <ButtonWrapper>
                    <Button
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </ButtonWrapper>
              }
            </EditorWrapper>
        }
      </Wrapper>
    );
  }
}

export default InPlaceText;
