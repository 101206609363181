import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import autobind from 'autobind-decorator';
import CardIndexRow from 'pages/builder/cards/card_index_row';
import { CardStore, CardWordAssociationStore } from '@seedlang/stores';
import styled from '@emotion/styled';
import Modal from 'components/modal';
import InPlaceText from 'components/form/in_place_text';

const Wrapper = styled.div`
  .card-index {
    .word.highlight {
      border-bottom: 2px solid red;
    }
    .text-wrapper {
      display: flex;
      .icons {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .fa-external-link {
          margin-left: 5px;
        }
      }
      .text {
        flex: 1;
      }
    }
    tr.current {
      td {
        background: rgba(255, 255, 207, 0.49);
      }
    }
    tr.inactive {
      td {
        background: #EFEFEF;
      }
    }
  }
`;

const Title = styled.div`
  font-weight: bold;
  border-bottom: 1px solid #CCC;
`;

@observer
class CardIndex extends React.Component {
  @observable cardForGrammarTip;

  @autobind toggleCardWordAssociation(card, wordAssociation) {
    const cardWordAssociation = card?.cardWordAssociations.find(item => item.wordAssociationId === wordAssociation.id);
    if (cardWordAssociation) {
      CardWordAssociationStore.destroy({ids: {cardWordAssociationId: cardWordAssociation.id}}, this.afterDeleteCardWordAssociation.bind(this, card, wordAssociation));
    } else {
      CardWordAssociationStore.create({data: {cardId: card.id, wordAssociationId: wordAssociation.id}}, this.afterCreateCardWordAssociation.bind(this, card, wordAssociation));
    }
    if (card.cardType.slug === "vocab_sentence_translate") {
      const comprehendCard = this.props.cards.find(item => card.translationId === item.translationId && card.id !== item.id && item.cardType.slug === "comprehend");
      const comprehendCardWordAssociation = comprehendCard?.cardWordAssociations.find(item => item.wordAssociationId === wordAssociation.id);
      if ((cardWordAssociation && comprehendCardWordAssociation) || (!cardWordAssociation && !comprehendCardWordAssociation)) {
        this.toggleCardWordAssociation(comprehendCard, wordAssociation);
      }
    }
  }

  @autobind afterCreateCardWordAssociation(card, wordAssociation, resp) {
    let cardWordAssociations = card.cardWordAssociations;
    cardWordAssociations.push({id: resp.id, wordAssociationId: wordAssociation.id});
    card.set('cardWordAssociations', cardWordAssociations);
  }

  @autobind afterDeleteCardWordAssociation(card, wordAssociation, resp) {
    card.set('cardWordAssociations', card.cardWordAssociations.filter(item => item.wordAssociationId !== wordAssociation.id));
  }

  @autobind onDeleteCard(cardId) {
    CardStore.destroy({ids: {cardId: cardId}}, this.props.afterChange)
  }

  @autobind setCardForGrammarTip(card) {
    this.cardForGrammarTip = card;
  }

  @autobind onCloseModal() {
    this.cardForGrammarTip = null;
    this.props.afterChange();
  }

  render() {
    return (
      <Wrapper>
        {
          this.cardForGrammarTip &&
            <Modal
              onCloseModal={this.onCloseModal}
              width="600px"
              marginTop="100px"
              height="770px"
            >
              <Title style={{marginBottom: "10px"}}>
                Card's Word or Sentence
              </Title>
              <InPlaceText
                defaultValue={this.cardForGrammarTip.grammarTipTitle}
                field="grammar_tip_title"
                id={this.cardForGrammarTip.id}
                model="cards"
              />
              <Title style={{margin: "10px 0"}}>
                Tip
              </Title>
              <InPlaceText
                richText
                defaultValue={this.cardForGrammarTip.grammarTip}
                field="grammar_tip"
                id={this.cardForGrammarTip.id}
                model="cards"
                inputType="textarea"
                // rejectBlank={false}
              />
            </Modal>
        }

        <div className="card-index">
          <table
            className="table-wrapper"
          >
            <thead>
              <tr>
                <th>
                  #
                </th>
                <th>
                  Tip
                </th>
                <th
                >
                  Type
                </th>
                <th>

                </th>
                <th
                >
                  Target / Source
                </th>
                <th>
                  Video
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                this.props.cards && this.props.cards.map((item) => {
                  return (
                    <CardIndexRow
                      key={item.id}
                      card={item}
                      deck={this.props.deck}
                      onDeleteCard={this.onDeleteCard}
                      cards={this.props.cards}
                      toggleCardWordAssociation={this.toggleCardWordAssociation}
                      afterChange={this.props.afterChange}
                      showMissingUnderlines={this.props.showMissingUnderlines}
                      setCardForGrammarTip={this.setCardForGrammarTip}
                    />
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </Wrapper>
    );
  }
}

export default CardIndex;
