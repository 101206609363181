import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import styled from '@emotion/styled';
import Text from 'components/text';
import Button from 'components/button/button';
import autobind from 'autobind-decorator';
import { Theme } from '@seedlang/constants';
import isBlank from 'is-blank';
import SlideWrapper from 'components/hoc/slide_wrapper.js';
import FormWrapper from 'components/authenticate/form_wrapper';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';
import WhitelabelLogo from "components/whitelabel_logo";

const Wrapper = styled.div`
  ${flexCenterColumn()}
  @media only screen and (min-width : 649px) {
    height: 100%;
  }
  @media only screen and (max-width : 649px) {
    padding-top: 40px;
  }
  width: 100%;
`;

const Content = styled.div`
  ${flexCenterColumn()}
  width: 100%;
`;

const Label = styled.div`
  font-size: 14px;
`;

const PatreonText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  font-size: 18px;
`;

@observer
class OnboardingPassword extends React.Component {
  @observable password;
  @observable passwordConfirmation;

  @computed get disabled() {
    return isBlank(this.password) || this.password !== this.passwordConfirmation || this.password.length < 6;
  }

  @autobind onSubmitForm(e) {
    e.preventDefault();
    if (!this.disabled) {
      this.props.onSubmitPassword(this.password);
    }
  }

  render() {
    if (this.props.showSpinner) {
      return null;
    }
    return (
      <Wrapper>
        <Content>
          <FormWrapper padding={0} color={AppUI.siteIsDefault ? "#FFF" : "#000"}>
            {
              window.location.href.match("from_patreon") &&
                <PatreonText>
                  <a href=".">
                    <WhitelabelLogo
                      image={AppUI.site?.image}
                    />
                  </a>
                  <div style={{marginTop: '20px'}}>You have successfully connected to Patreon and your Easy Catalan Membership account has been created with the following email address:</div>
                  <div style={{fontWeight: 'bold'}}>{AppUI.user?.email}</div>
                  <div>Please create a password for future logins using the email address above.</div>
                </PatreonText>
            }
            {
              !window.location.href.match("from_patreon") &&
              <Text
                heading="3"
                center
                margin="0 0 20px 0"
                color={AppUI.siteIsDefault ? "#FFF" : "#000"}
              >
                Set Your Password
              </Text>
            }
            <form
              onSubmit={this.onSubmitForm}
            >
              <div>
                <label>Password</label>
                <input
                  type="password"
                  autoFocus={this.props.show}
                  value={this.password || ''}
                  onChange={(e) => this.password = e.currentTarget.value}
                  style={{marginBottom: 10}}
                />
              </div>

              <div>
                <label>Password Confirmation</label>
                <input
                  type="password"
                  value={this.passwordConfirmation || ''}
                  onChange={(e) => this.passwordConfirmation = e.currentTarget.value}
                />
              </div>

              <Button
                margin="20px 0 0 0"
                height={AppUI.site.isDefault ? null : '50px'}
                onClick={this.onSubmitForm}
                backgroundColor={Theme.green}
                width="100%"
                disabled={this.disabled}
              >
                {AppUI.site.isDefault ? 'Submit' : 'Continue'}
              </Button>
            </form>
          </FormWrapper>
        </Content>
      </Wrapper>
    );
  }
}

export default SlideWrapper(OnboardingPassword);;
