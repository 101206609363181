"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getCookieValue;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _is_present = _interopRequireDefault(require("./is_present"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function getCookieValue(name) {
  var value = _jsCookie["default"].get(name, {
    path: '/'
  });
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else {
    return (0, _is_present["default"])(value) ? value : null;
  }
}