"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = amountWithCurrency;
function amountWithCurrency(amount, currency) {
  var currencySymbol = currency === 'usd' ? '$' : '€';
  var seperator = currency === 'usd' ? '.' : ',';
  return "".concat(currencySymbol).concat(amount.toFixed(2).replace(".", seperator));
}