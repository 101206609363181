"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var Layout = {
  VIDEO_TEXT_HEIGHT: 100,
  MINIMUM_VIDEO_TEXT_HEIGHT: 80,
  MAXIMUM_VIDEO_TEXT_HEIGHT: 120,
  MAX_DEFAULT_CARD_TEXT_FONT_SIZE: 28,
  MAX_MINIMUM_CARD_TEXT_FONT_SIZE: 22,
  MAX_MAXIMUM_CARD_TEXT_FONT_SIZE: 48,
  MINIMUM_CARD_TEXT_FONT_SIZE: 13,
  MAX_DECK_WIDTH: 500,
  DESKTOP_DECK_HEADER_HEIGHT: 60,
  MOBILE_DECK_HEADER_HEIGHT: 50,
  MINIMUM_DECK_HEADER_HEIGHT: 45,
  MAXIMUM_DECK_HEADER_HEIGHT: 70,
  MULTIPLE_CHOICE_BUTTON_HEIGHT: 100,
  MAXIMUM_MULTIPLE_CHOICE_BUTTON_HEIGHT: 120,
  MINIMUM_MULTIPLE_CHOICE_BUTTON_HEIGHT: 80,
  MAXIMUM_BUTTON_HEIGHT: 100,
  MOBILE_BUTTON_HEIGHT: 90,
  DESKTOP_BUTTON_HEIGHT: 70,
  MINIMUM_BUTTON_HEIGHT: 60,
  USER_INPUT_HEIGHT: 55,
  CARD_ARROW_BOX_HEIGHT: 70,
  CARD_ARROW_BOX_WIDTH: 260,
  MINIMUM_USER_INPUT_HEIGHT: 42,
  MINIMIZED_CONCEPT_DESCRIPTION_HEIGHT: 32,
  NATIVE_HEADER_HEIGHT: 50,
  MOBILE_HEADER_HEIGHT: 50,
  MOBILE_FOOTER_HEIGHT: 40,
  SIDEBAR_GAP: 50,
  DECK_PROGRESS_BAR_HEIGHT: 8,
  DESKTOP_FEED_VIDEO_WIDTH: 400,
  FEED_MINIMIZED_SUPPLEMENT_WIDTH: 140,
  DESKTOP_VIDEO_WIDTH: 280,
  MEDIA_RECORDER_HEIGHT: 60,
  MIN_DECK_HEIGHT: 450,
  MAX_DECK_HEIGHT: 800,
  HOME_RIGHT_COLUMN_WIDTH: 280,
  BUTTON_MESSAGE_HEIGHT: 15,
  MULTIPLE_CHOICE_BUTTONS_EXTRA_HEIGHT: 50
};
var _default = Layout;
exports["default"] = _default;