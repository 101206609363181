import React from 'react';
import { observer } from 'mobx-react';
import { SentenceTranslateCardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import MediaSection from 'components/vocab_card/media_section';
import CardSection from 'components/vocab_card/card_section';
import { DeckUI } from '@seedlang/state';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import Text from 'components/text';
import SentenceBreakdown from 'components/sentence_breakdown';
import TextResizer from 'components/text_resizer';
import SpecialCharacterButtons from 'components/special_character_buttons';
import { flexCenter, flexCenterColumn } from '@seedlang/style_mixins';
import { Constants, Theme } from '@seedlang/constants';
import TranslateButtons from 'components/vocab_card/translate_buttons';
import CardUserInput from 'components/card/card_user_input';
import CardArrowBox from 'components/card/card_arrow_box';
import { isBlank } from '@seedlang/utils';
import Spinner from 'components/spinner';
import Button from 'components/button/button';
import { BrokenIcon } from '@seedlang/icons';
import HintWarning from "components/card/hint_warning";
import { AppUI } from "@seedlang/state";
import TranslateSentenceIcon from "components/icons/translate_sentence_icon";

const SentenceWrapper = styled.div`
  ${flexCenterColumn()}
  flex: 1;
  width: 100%;
  height: 100%;
`;

const CharacterWrapper = styled.div`
  ${flexCenter()}
  width: 100%;
  background: #CCC;
  height: ${(props) => props.height};
`;

const SentenceCharacterWrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  height: 100%;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: ${Theme.z["card-3"]};
  .fa-check {
    color: ${Theme.green};
  }
  .fa-times {
    color: ${Theme.red};
  }
`;

const ErrorLoading = styled.div`
  width: 280px;
  background: white;
  margin: auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  line-height: 18px;
  color: #333;
  padding: 10px;
  svg {
    width: 30px;
  }
`;

const ErrorText = styled.div`
  margin: 10px 0;
`;

@observer
class SentenceTranslateCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        showWordCard={this.props.showWordCard}
        icons={this.props.icons}
      >
        <CardContent>
          {
            this.props.cardState.revealed &&
              <MediaSection
                showMedia
                autoPlay={this.props.inView}
                card={this.props.card}
                cardState={this.props.cardState}
                height={this.props.sectionHeight}
                borderTopRadius={10}
                afterVideoPlayback={this.props.afterVideoPlayback}
              />
          }
          <CardSection
            background="#FFF"
            padding="20px"
            numSections={this.props.numSections}
            borderTopRadius={this.props.cardState.revealed ? 0 : 10}
            height={this.props.sectionHeight}
          >
            <TextResizer
              resizeText
              height={this.props.sectionHeight - 20}
              width={DeckUI.layout.innerDeckWidth - 40}
              characterLength={this.props.topSectionText && this.props.topSectionText.length}
            >
              <Text
                rtl={Constants.RTL_LANGUAGE_IDS.indexOf(this.props.topSectionLanguageId) !== -1}
              >
                {this.props.topSectionText}
              </Text>
            </TextResizer>
          </CardSection>
          <CardSection
            numSections={this.props.numSections}
            padding="20px"
            background="#CCC"
            borderBottomRadius={this.props.showUserInput ? 0 : 10}
            height={this.props.sectionHeight}
          >
            {
              this.props.cardState.revealed && AppUI.user.translator &&
                <TranslateSentenceIcon
                  card={this.props.card}
                />
            }
            {
              !this.props.showUserInput && this.props.cardState.revealed &&
                <IconWrapper>
                  {
                    this.props.userCard.correct &&
                      <i className='fa fa-check' />
                  }
                  {
                    !this.props.userCard.correct &&
                      <i className='fa fa-times' />
                  }
                </IconWrapper>
            }
            <SentenceCharacterWrapper>
              <SentenceWrapper>
                {
                  isBlank(this.props.card.sentence.enrichedWords) && !this.props.showRefreshButton &&
                    <Spinner />
                }
                {
                  this.props.showRefreshButton &&
                    <ErrorLoading>
                      <BrokenIcon />
                      <ErrorText>
                        {AppUI.getErrorMessage('sentence')}
                      </ErrorText>
                      <Button
                        onClick={this.props.onRefreshSentence}
                      >
                        Refresh Sentence
                      </Button>
                    </ErrorLoading>
                }
                {
                  this.props.showUserInput &&
                    <TextResizer
                      resizeText
                      maximumFontSize={30}
                      height={this.props.sectionHeight - 20}
                      width={DeckUI.layout.deckWidth - 40}
                      characterLength={this.props.card.sentence && this.props.card.sentence.targetText && this.props.card.sentence.targetText.length}
                    >
                      <SentenceBreakdown
                        center
                        showUnderline={this.props.cardState.revealed}
                        showGapUnderline={!this.props.cardState.revealed}
                        disableOnClick={!this.props.cardState.revealed}
                        inputWordAssociationIds={this.props.cardState.revealed ? null : this.props.card.wordAssociationIds}
                        highlightWordAssociationIds={this.props.cardState.revealed ? this.props.card.wordAssociationIds : null}
                        sentence={this.props.card.sentence}
                      />
                    </TextResizer>
                }
                {
                  !this.props.showUserInput &&
                    <TextResizer
                      resizeText
                      maximumFontSize={30}
                      height={this.props.sectionHeight - 20}
                      width={DeckUI.layout.deckWidth - 40}
                      characterLength={this.props.card.sentence && this.props.card.sentence.targetText && this.props.card.sentence.targetText.length}
                    >
                      <SentenceBreakdown
                        center
                        hideTextInput={!this.props.inView}
                        caretPosition={this.props.caretPosition}
                        focusedWordAssociationId={this.props.inView && this.props.focusedWordAssociationId}
                        showUnderline={this.props.cardState.revealed}
                        onSubmit={() => this.props.onSubmit ? this.props.onSubmit() : DeckUI.onEvaluateTextInput(this.props.card, this.props.cardState, this.props.userCard)}
                        onInputChange={this.props.onInputChange}
                        inputWordAssociationIds={this.props.card.wordAssociationIds}
                        sentence={this.props.card.sentence}
                        inputValues={this.props.inputValues}
                        onInputFocus={this.props.onInputFocus}
                        disableOnClick={!this.props.cardState.revealed}
                        inputCorrect={this.props.inputValuesCorrect && (this.props.cardState.revealed || DeckUI.user.evaluateVocabOnKeystroke)}
                        inputIncorrect={this.props.cardState.revealed && this.props.inputValuesCorrect === false}
                        inputValuesCorrectByWordAssociationId={this.props.inputValuesCorrectByWordAssociationId}
                      />
                    </TextResizer>
                }
                {
                  this.props.showAccentWarning && !this.props.cardState.revealed &&
                    <HintWarning
                      marginTop={'20px'}
                    >
                      {Constants.WARNINGS['accents']}
                    </HintWarning>
                }
                {
                  this.props.showSynonymWarning && !this.props.cardState.revealed &&
                    <HintWarning
                      marginTop={'20px'}
                    >
                      {Constants.WARNINGS['synonym']}
                    </HintWarning>
                }
                {
                  this.props.showLetterCaseWarning && !this.props.cardState.revealed &&
                    <HintWarning
                      marginTop={'20px'}
                    >
                      {Constants.WARNINGS['letterCase']}
                    </HintWarning>
                }
                {
                  this.props.showMultipleWordsWarning && !this.props.cardState.revealed &&
                    <HintWarning
                      marginTop={'20px'}
                    >
                      {Constants.WARNINGS['needsMoreWords']}
                    </HintWarning>
                }
              </SentenceWrapper>
              {
                !this.props.showUserInput && !this.props.cardState.revealed &&
                  <CharacterWrapper
                    height={DeckUI.layout.isMobile ? "36px" : "26px"}
                  >
                    {
                      this.props.focusedWordAssociationId &&
                        <SpecialCharacterButtons
                          center
                          fontSize={DeckUI.layout.isMobile ? "12px" : "16px"}
                          onClick={this.props.onCharacterClick}
                        />
                    }
                  </CharacterWrapper>
              }
            </SentenceCharacterWrapper>
          </CardSection>
          {
            this.props.showUserInput &&
              <CardUserInput
                card={this.props.card}
                cardState={this.props.cardState}
                userCard={this.props.userCard}
              />
          }
          <CardArrowBox
            possibleArrowBoxes={this.props.possibleArrowBoxes}
            currentArrowBox={this.props.currentArrowBox}
          />
        </CardContent>
        <TranslateButtons
          card={this.props.card}
          cardState={this.props.cardState}
          userCard={this.props.userCard}
          disabled={!this.props.inView}
          onNextClick={this.props.onNextClick}
          onShowAnswer={this.props.onShowAnswer}
        />
      </CardWrapper>
    );
  }
}

export default SentenceTranslateCardWrapper(SentenceTranslateCard);
