import React from 'react';
import { observer } from 'mobx-react';
import { ExerciseUI } from '@seedlang/state';
import ExerciseSentence from 'components/exercise/exercise_sentence';
import ExerciseWordAssociations from 'components/exercise/exercise_word_associations';
import styled from '@emotion/styled';
import Prompt from 'components/exercise/prompt';

const Wrapper = styled.div`

`;

const ExerciseEntry = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dadada;
`;

const Sentences = styled.div`
  margin-top: 20px;
`;

@observer
class ExerciseInputDrag extends React.Component {

  render() {
    return (
      <Wrapper>
        <ExerciseWordAssociations
          clickable
          wordAssociations={ExerciseUI.exercise.shuffledSelectedWordAssociations}
          assignedWordAssociationIds={ExerciseUI.exercise.state.assignedWordAssociationIds}
          onSetValueToWordAssociation={ExerciseUI.onSetValueToWordAssociation}
          setAssignedWordAssociations={ExerciseUI.setAssignedWordAssociations}
          onClick={ExerciseUI.assignToNextWordAssociation}
          inDeck={this.props.inDeck}
        />
        <Sentences>
          {
            ExerciseUI.exercise.exerciseEntries.map(item => {
              return (
                <ExerciseEntry
                  key={item.id}
                  grammarTip={item.grammarTip}
                  {...this.props}
                >
                  {
                    item.prompt &&
                      <Prompt>
                        {item.prompt}
                      </Prompt>
                  }
                  {
                    item.sentence &&
                      <ExerciseSentence
                        inputType='reorder'
                        sentence={item.sentence}
                        revealed={ExerciseUI.exercise.state.revealed}
                        exercise={ExerciseUI.exercise}
                      />
                  }
                </ExerciseEntry>
              )
            })
          }
        </Sentences>
      </Wrapper>
    );
  }
}

export default ExerciseInputDrag;
