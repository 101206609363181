import React from 'react';
import { observer } from 'mobx-react';
import { WordOptionsCardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import MediaWithSupplement from 'components/media/media_with_supplement';
import CardSection from 'components/vocab_card/card_section';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import MultipleChoiceButtons from 'components/card/multiple_choice_buttons';
import { flexCenterColumn } from '@seedlang/style_mixins';
import EvaluateButtons from 'components/card/evaluate_buttons';
import { pixify } from '@seedlang/utils';
import { AppUI, DeckUI } from '@seedlang/state';
import CardWordText from "components/card/card_word_text";


const MediaSection = styled.div`
  width: 100%;
  ${flexCenterColumn()}
`;

const PromptText = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #FFF;
  font-style: italic;
`;

@observer
class MinimalPairCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        icons={this.props.icons}
      >
        <CardContent
          height={pixify(this.props.cardState.revealed ? DeckUI.layout.contentHeight : DeckUI.layout.contentHeightWithMultipleChoiceButtons(2))}
        >
          <MediaSection>
            <MediaWithSupplement
              card={this.props.card}
              cardState={this.props.cardState}
              autoPlay={this.props.inView}
              borderTopRadius={10}
              afterVideoPlayback={this.props.afterVideoPlayback}
              videoClip={ this.props.card.targetVideoClip }
              supplementVideoClip={this.props.card.supplementVideoClip}
              supplementImage={this.props.card.supplementImage}
              usingDataSaverHeader={AppUI.usingDataSaverHeader}
              supplementBottom={DeckUI.layout.supplementBottom}
              loopSupplement={DeckUI.user.loopSupplement}
              hideSupplement={!this.props.showDeckSupplement}
              maximizedWidth={pixify(DeckUI.layout.videoHeight)}
              minimizedWidth={pixify(DeckUI.layout.supplementWidth)}
              supplementMaximized={this.props.supplementMaximized}
              loadVideo={!this.props.showHelp}
              marginBottom={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
            />
          </MediaSection>
          {
            !this.props.cardState.revealed &&
              <CardSection
                background="#5d5d5d"
                borderBottomRadius={10}
                height={pixify(DeckUI.layout.contentHeightWithMultipleChoiceButtons(2) - DeckUI.layout.videoHeight)}
              >
                <PromptText>
                  Which word do you hear?
                </PromptText>
              </CardSection>
          }
          {
            this.props.cardState.revealed &&
              <CardWordText
                showSource
                card={this.props.card}
                cardState={this.props.cardState}
                sectionHeight={DeckUI.layout.userInputHeight + DeckUI.layout.cardTextHeight}
                showType={this.props.cardState.showType}
                correct={this.props.userCard.correct}
              />
          }
        </CardContent>
        {
          !this.props.cardState.revealed &&
            <MultipleChoiceButtons
              disabled={!this.props.inView}
              height={DeckUI.layout.totalMultipleChoiceButtonHeight(2)}
              fontSize="20px"
              buttonHeight={DeckUI.layout.multipleChoiceButtonHeight}
              width={DeckUI.layout.innerDeckWidth}
              options={this.props.vocabMultipleChoiceOptions}
              onClick={(option) => this.props.onClick(option)}
              selected={[this.props.multipleChoiceSelected]}
              isMobile={DeckUI.layout.isMobile}
              correct={this.props.correct}
              field="text"
            />
        }
        {
          this.props.cardState.revealed &&
            <EvaluateButtons
              card={this.props.card}
              onNextClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
              cardState={this.props.cardState}
              userCard={this.props.userCard}
              disabled={!this.props.inView}
            />
        }
      </CardWrapper>
    );
  }
}

export default WordOptionsCardWrapper(MinimalPairCard);
