import React from 'react';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`

`;

@observer
class RedirectToHome extends React.Component {

  constructor(props) {
    super(props);
    this.disposer = autorun(() => {
      if (AppUI.authUserStore.hasShowData) {
        AppUI.routeToHome();
      }
    })
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  render() {
    return (
      <Wrapper />
    );
  }
}

export default ErrorBoundary(RedirectToHome);
