import React from 'react';
import { observer } from 'mobx-react';
import InPlaceLanguageSelect from 'components/form/in_place_language_select';
import Text from 'components/text';
import styled from '@emotion/styled';
import SlideWrapper from 'components/hoc/slide_wrapper.js';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';
import Button from 'components/button/button';
import { Theme, Languages } from '@seedlang/constants';

const Wrapper = styled.div`
  width: 100%;
  ${flexCenterColumn()}
  height: 100%;
`;

const Content = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  flex: 1;
`;

const ButtonWrapper = styled.div`
  height: 100px;
  width: 100%;
`;

const Support = styled.div`
  margin: 20px 0 0 0;
  color: #FFF;
`;

// const BritishEnglish = styled.div`
//   font-size: 12px;
//   line-height: 17px;
//   margin: 10px 0;
//   background: ${Theme.orange};
//   color: white;
//   padding: 10px;
// `;

@observer
class OnboardingNativeLanguage extends React.Component {

  render() {
    return (
      <Wrapper>
        <Content>
          <Text
            center
            heading="3"
            margin="0 0 20px 0"
            color="#FFF"
          >
            What is your native language?
          </Text>
          <InPlaceLanguageSelect
            value={this.props.languageId}
            onChange={this.props.onSubmitNativeLanguageId}
            showEdit
          />
          {
            this.props.languageId !== 'EN' &&
              <Support
              >
                {
                  this.props.languagesMessageNum === 1 &&
                    <span>
                      Ahhh, <b>{Languages[this.props.languageId]}</b>. One of our favorites 👍.
                    </span>
                }
                {
                  this.props.languagesMessageNum === 2 &&
                    <span>
                      We love <b>{Languages[this.props.languageId]}</b>! 👍.
                    </span>
                }
                {
                  this.props.languagesMessageNum === 3 &&
                    <span>
                      Such a beautiful language, <b>{Languages[this.props.languageId]}</b>! 👍.
                    </span>
                }
              </Support>
          }
          <Text
            fontSize={AppUI.layout.isMobile ? "14px" : "16px"}
            lineHeight={AppUI.layout.isMobile ? "15px" : "18px"}
            margin="20px 0 0 0"
            center
            bold
            color="#FFF"
          >
            Our <b>Vocab section</b> has many community-submitted translations in languages other than English.
          </Text>
        </Content>
        <ButtonWrapper>
          <Button
            background="#FFF"
            color={Theme.blue}
            scaleOnHover="1.05"
            height="50px"
            borderRadius="50px"
            backgroundOnHover="#FFF"
            width="100%"
            onClick={this.props.afterSettingLanguage}
          >
            Continue
          </Button>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

export default SlideWrapper(OnboardingNativeLanguage);
