import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from '@emotion/styled';
import SlideWrapper from 'components/hoc/slide_wrapper.js';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import Text from 'components/text';
import LearningPreferenceButton from 'components/onboarding/learning_preference_button';
import { AppUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils';
import { DictionaryIcon, SpeakingIcon, TriviaCircleIcon, WordListIcon } from '@seedlang/icons';
import Spinner from 'components/spinner';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  height: 100%;
`;


const Content = styled.div`
  ${flexCenterColumn()}
  width: 100%;
`;

const UnderlinedText = styled.div`
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
  margin-top: 20px;
  &:hover {
    text-decoration:  none;
  }
`;


@observer
class OnboardingChooseDeck extends React.Component {

  @computed get showWorksheets() {
    return window.location.href.split("/group/")[1] === 'f74a4102-d65b-448c-b261-60b6be2c7eca';
  }

  @computed get wordsNumber() {
    if (isPresent(AppUI.targetLanguage)) {
      return (Math.floor(AppUI.targetLanguage.vocabTrainerWordsCount/100)*100).toLocaleString('en');
    } else if (this.props.targetLanguageId === 'DE') {
      return '20,000';
    } else {
      return '3000';
    }
  }

  render() {
    return (
      <Wrapper>
        <Content>
          <Text
            heading="3"
            color="#FFF"
            margin={AppUI.user.levelAbbreviation === 'A1' ? "-20px 0 10px 0" : "0 0 10px 0"}
          >
            How would you like to start?
          </Text>
          {
            this.props.showSpinner &&
              <Spinner
                background="white"
              />
          }
          {
            !this.props.showSpinner &&
              <div>
                {
                  this.showWorksheets &&
                    <LearningPreferenceButton
                      isMobile={AppUI.layout.isMobile}
                      height="100px"
                      icon={<TriviaCircleIcon />}
                      onClick={() => AppUI.routeStore.routeToNamed('groups.show', {groupId: "f74a4102-d65b-448c-b261-60b6be2c7eca"})}
                      background={Theme.purple}
                      name="Find exercises from Easy German videos."
                    />
                }
                <LearningPreferenceButton
                  isMobile={AppUI.layout.isMobile}
                  height="100px"
                  icon={<SpeakingIcon />}
                  onClick={() => this.props.onSelectLearningPreference('content_tree')}
                  background={Theme.orange}
                  name="Give me speaking and listening practice."
                />
                <LearningPreferenceButton
                  isMobile={AppUI.layout.isMobile}
                  height="100px"
                  icon={<DictionaryIcon />}
                  onClick={() => this.props.onSelectLearningPreference('vocab')}
                  background={Theme.green}
                  name="Help me to increase my vocabulary."
                />
                {
                  AppUI.targetLanguageId === 'DE' && !this.showWorksheets &&
                    <LearningPreferenceButton
                      isMobile={AppUI.layout.isMobile}
                      height="100px"
                      icon={<TriviaCircleIcon />}
                      onClick={() => this.props.onSelectLearningPreference('trivia')}
                      background={Theme.purple}
                      name="I'd like to learn while playing trivia."
                    />
                }
                {
                  AppUI.targetLanguageId !== 'DE' && !this.showWorksheets &&
                    <LearningPreferenceButton
                      isMobile={AppUI.layout.isMobile}
                      height="100px"
                      icon={<WordListIcon />}
                      onClick={() => this.props.onSelectLearningPreference('conjugation')}
                      background={Theme.purple}
                      name="I'd like to learn the conjugation of verbs."
                    />
                }
              </div>
          }
        </Content>
      </Wrapper>
    );
  }
}

export default SlideWrapper(OnboardingChooseDeck);
