import React from 'react';
import { observer } from 'mobx-react';
import { AppUI, DeckUI } from '@seedlang/state';
import { DeckCloseWebWrapper, TranslateCardWrapper } from '@seedlang/hoc';
import CardUserInput from 'components/card/card_user_input';
import CardText from 'components/card/card_text';
import { pixify, isBlank, isPresent } from '@seedlang/utils';
import MediaWithSupplement from 'components/media/media_with_supplement';
import { LeftIcon, DownIcon, HelpIcon } from '@seedlang/icons';
import NoUserInput from 'components/media/no_user_input';
import { Theme } from '@seedlang/constants';
import styled from '@emotion/styled';
import EvaluateButtons from 'components/card/evaluate_buttons';
import CardButtons from 'components/card/card_buttons';
import CardButton from 'components/card/card_button';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import { flexCenterColumn } from '@seedlang/style_mixins';
import VideoPlayer from 'components/media/video_player';
import CardArrowBox from 'components/card/card_arrow_box';
import Spinner from 'components/spinner';

const MediaSection = styled.div`
  width: 100%;
  ${flexCenterColumn()}
  display: ${props => props.display};
`;

const HelpVideo = styled.div`
  height: ${props => props.height};
  position: relative;
`;

const EmptyVideoPlayer = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
  background: #000;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HelpWordWrapper = styled.div`
  width: ${props => props.width};
  background: white;
  padding: 10px;
  border-radius: 10px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HelpWord = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const HelpWordNote = styled.div`
  font-style: italic;
  font-size: 12px;
  margin-top: 5px;
  color: #333;
`;

@observer
class TranslateCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        showWordCard={this.props.showWordCard}
        icons={this.props.icons}
      >
        <CardContent>
          <MediaSection
            display={!this.props.showHelp && this.props.showVideoWithSupplement ? 'flex' : 'none'}
          >
            <MediaWithSupplement
              autoPlay={this.props.inView && !this.props.showHelp && this.props.showVideoWithSupplement}
              borderTopRadius={10}
              borderBottomRadius={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
              videoClip={ this.props.card.targetVideoClip }
              supplementVideoClip={this.props.card.supplementVideoClip}
              supplementImage={this.props.card.supplementImage}
              usingDataSaverHeader={AppUI.usingDataSaverHeader}
              supplementBottom={DeckUI.layout.supplementBottom}
              loopSupplement={DeckUI.user.loopSupplement && this.props.card.cardRule && this.props.card.cardRule.loopSupplement}
              hideSupplement={!this.props.showDeckSupplement}
              maximizedWidth={pixify(DeckUI.layout.videoHeight)}
              minimizedWidth={pixify(DeckUI.layout.supplementWidth)}
              supplementMaximized={this.props.supplementMaximized}
              loadVideo={!this.props.showHelp}
              marginBottom={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
              afterVideoPlayback={this.props.afterVideoPlayback}
            />
          </MediaSection>
          {
            this.props.showHelp && (isBlank(this.props.helpVideoClip) || this.props.loadHelpWord) &&
              <EmptyVideoPlayer
                width={pixify(DeckUI.layout.innerDeckWidth)}
                height={pixify(DeckUI.layout.innerDeckWidth)}
              >
                {
                  this.props.loadHelpWord &&
                    <Spinner
                      background="white"
                    />
                }
                {
                  !this.props.loadHelpWord && isBlank(this.props.helpVideoClip) && isPresent(this.props.cardState.helpWord) &&
                    <HelpWordWrapper
                      width={pixify(DeckUI.layout.videoHeight - 60)}
                    >
                      <HelpWord>
                        "{this.props.cardState.helpWord.targetText}"
                      </HelpWord>
                      <HelpWordNote>
                        We will shoot a video for this word soon!
                      </HelpWordNote>
                    </HelpWordWrapper>
                }
              </EmptyVideoPlayer>
          }
          {
            !this.props.loadHelpWord && this.props.showHelp && isPresent(this.props.helpVideoClip) &&
              <HelpVideo
                height={pixify(DeckUI.layout.videoHeight)}
              >
                <VideoPlayer
                  maximized
                  autoPlay
                  usingDataSaverHeader={AppUI.usingDataSaverHeader}
                  videoClip={this.props.helpVideoClip}
                  width={pixify(DeckUI.layout.innerDeckWidth)}
                  borderTopRadius={10}
                />
              </HelpVideo>
          }
          <CardText
            card={this.props.card}
            cardState={this.props.cardState}
            hasUserInput
            showHelp={this.props.showHelp}
            padding="0 30px"
            cardId={this.props.card.id}
            sentence={this.props.card.sentence}
            cardTypeSlug={this.props.card && this.props.card.cardTypeSlug}
            showText
            showEdit
            expand={!this.props.showHelp && !this.props.cardState.revealed}
            showType={!this.props.showHelp && !this.props.cardState.revealed ? 'source' : this.props.cardState.showType}
            borderTopRadius={this.props.cardTextBorderTopRadius}
            borderBottomRadius={this.props.cardTextBorderBottomRadius}
            onClickHelpWord={this.props.onClickHelpWord}
          />
          {
            !this.props.showHelper && !this.props.showUserInput &&
              <NoUserInput />
          }
          {
            !this.props.showHelp && this.props.showUserInput &&
              <CardUserInput
                disabled={!this.props.inView}
                card={this.props.card}
                cardState={this.props.cardState}
              />
          }
          <CardArrowBox
            possibleArrowBoxes={this.props.possibleArrowBoxes}
            currentArrowBox={this.props.currentArrowBox}
          />
        </CardContent>
        {
          this.props.showEvaluationButtons &&
            <EvaluateButtons
              card={this.props.card}
              cardState={this.props.cardState}
              userCard={this.props.userCard}
              onNextClick={this.props.onNextClick}
              advanceCard={this.props.advanceCard}
            />
        }
        {
          this.props.showUnrevealedTranslateButtons &&
            <CardButtons>
              <CardButton
                shortcut="1"
                text="Help"
                icon={<HelpIcon />}
                marginRight={this.props.showUserInput ? 0 : 5}
                disabled={!this.props.inView}
                onClick={() => this.props.setShowHelp(true)}
                backgroundColor={Theme.darkOrange}
              />
              {
                !this.props.showUserInput &&
                  <CardButton
                    submitOnEnter
                    shortcut="2"
                    marginLeft={5}
                    text="Show Answer"
                    backgroundColor={Theme.green}
                    icon={<DownIcon />}
                    onClick={() => DeckUI.reveal(this.props.card, this.props.cardState)}
                    disabled={!this.props.inView || this.props.disableRevealButton}
                  />
              }
            </CardButtons>
        }
        {
          this.props.showHelp &&
            <CardButtons>
              <CardButton
                shortcut="1"
                submitOnEnter
                text="Try Again"
                icon={<LeftIcon />}
                className="green reveal reverse"
                disabled={!this.props.inView}
                onClick={() => this.props.setShowHelp(false)}
              />
            </CardButtons>
        }
      </CardWrapper>
    );
  }
}

export default DeckCloseWebWrapper(TranslateCardWrapper(TranslateCard));
