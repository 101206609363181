import React from 'react';
import { observer } from 'mobx-react';
import { DescribeCardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import { RightIcon, SendIcon } from '@seedlang/icons';
import { AppUI, DeckUI } from '@seedlang/state';
import { pixify } from '@seedlang/utils';
import VideoPlayer from 'components/media/video_player';
import CardButtons from 'components/card/card_buttons';
import CardButton from 'components/card/card_button';
import Spinner from 'components/spinner';
import CardUserInput from 'components/card/card_user_input';
import CompositeVideoPlayer from 'components/media/composite_video_player';
import { Theme } from '@seedlang/constants';
import NextButton from 'components/button/next_button';
import NoUserInput from 'components/media/no_user_input';
import CardArrowBox from 'components/card/card_arrow_box';
import FeedInfo from 'components/card/feed_info';

const DescribeVideo = styled.div`
  position: relative;
  height: ${(props) => props.height};
  background: #dadada;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
`;

@observer
class DescribeCard extends React.Component {

  render() {
    return (
      <CardWrapper>
        <CardContent
          padding={0}
        >
          <FeedInfo
            show={this.props.cardState.submittedToFeed && !this.props.cardState.submittedToFeedMessageClosed}
            feedEntryId={DeckUI.lastFeedEntryId}
            onClose={() => DeckUI.closeFeedInfo()}
            width={pixify(DeckUI.layout.videoHeight)}
            height={pixify(DeckUI.layout.videoHeight)}
          />
          {
            this.props.cardState.submittingToFeed &&
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
          }
          {
            !this.props.showCompositeVideoPlayer &&
              <DescribeVideo
                height={pixify(DeckUI.layout.describeContentHeight)}
              >
                <VideoPlayer
                  autoPlay
                  loop
                  muted
                  maximized
                  position="relative"
                  usingDataSaverHeader={AppUI.usingDataSaverHeader}
                  videoClip={ DeckUI.deck.conceptVideoClip }
                  width={pixify(DeckUI.layout.innerDeckWidth)}
                />
              </DescribeVideo>
          }
          {
            this.props.showCompositeVideoPlayer &&
              <CompositeVideoPlayer
                showNumbers
                borderTopRadius={10}
                height={pixify(DeckUI.layout.describeContentHeight)}
                width={pixify(DeckUI.layout.innerDeckWidth)}
                mediaItems={DeckUI.deck.feedEntryMediaItems}
                usingDataSaverHeader={AppUI.usingDataSaverHeader}
              />
          }
          {
            this.props.cardState.submittedToFeed &&
              <NoUserInput />
          }
          {
            !this.props.cardState.submittedToFeed &&
              <CardUserInput
                show
                disabled={!this.props.inView}
                card={this.props.card}
                cardState={this.props.cardState}
              />
          }
          <CardArrowBox
            possibleArrowBoxes={this.props.possibleArrowBoxes}
            currentArrowBox={this.props.currentArrowBox}
          />
        </CardContent>
        {
          !this.props.cardState.submittedToFeed &&
            <CardButtons>
              <CardButton
                shortcut="1"
                text="Skip"
                marginRight={5}
                backgroundColor={Theme.darkOrange}
                icon={<RightIcon />}
                disabled={!this.props.inView}
                onClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
              />
              <CardButton
                shortcut="2"
                text="Submit"
                marginLeft={5}
                backgroundColor={Theme.green}
                icon={<SendIcon />}
                disabled={!this.props.inView || DeckUI.disableRecordButton}
                onClick={() => DeckUI.onSubmitToFeed({xpXPos: DeckUI.layout.quarterDeckWidth * 3})}
              />
            </CardButtons>
        }
        {
          this.props.cardState.submittedToFeed &&
            <CardButtons>
              <NextButton
                disabled={!this.props.inView}
                onClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
                submitOnEnter
                shortcut="1"
              />
            </CardButtons>
        }
      </CardWrapper>
    );
  }
}

export default DescribeCardWrapper(DescribeCard);
