import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { Motion, spring } from 'react-motion';
import styled from '@emotion/styled';

const Wrapper = styled.div`
   width: calc(100% - 40px);
   position: absolute;
   height: 100%;
`;

const SlideWrapper = (Component, params={}) =>

  @observer
  class extends React.Component { // eslint-disable-line react/display-name

    @computed get opacity() {
      return this.props.show ? 1 : 0;
    };

    @computed get x() {
      if (this.props.show) {
        return 0;
      } else if (this.props.waiting) {
        return this.props.rightToLeft ? 800 : -800;
      } else {
        return this.props.rightToLeft ? -800 : 800;
      }
    };

    render() {
      if (!this.props.show && !this.props.waiting && !this.props.seen) return null;

      return (
        <Motion
          style={{
            opacity: spring(this.opacity),
            x: spring(this.x),
          }}
        >
          {
            ({opacity, x}) =>
              <Wrapper
                style={{
                  opacity: opacity,
                  WebkitTransform: `translate(${x}, 0)`,
                  transform: `translate(${x}px, 0)`,
                }}
              >
                <Component {...this.props} />
              </Wrapper>
          }
        </Motion>
      );
    }
  };

SlideWrapper.displayName = 'SlideWrapper';
export default SlideWrapper;
