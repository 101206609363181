import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { ServerStore } from '@seedlang/stores';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import FormWrapper from 'components/authenticate/form_wrapper';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import AlreadyExistsNotice from './already_exists_notice';
import RegistrationForm from './registration_form';

@observer
class AuthenticateRegister extends React.Component {
  @observable expandedEmailRegistrationFields = false;
  @observable alreadyExists = false;

  get isExpanded() {
    return !this.props.expandEmail || AppUI.expandedEmailRegistrationFields;
  }

  @computed get isButtonOnly() {
    return !this.props.signInCard && !this.props.signInModal && !AppUI.expandedEmailRegistrationFields;
  }

  componentDidMount() {
    ServerStore.clearMessages();
  }

  @autobind expandFormFields() {
    AppUI.set('expandedEmailRegistrationFields', true);
  }

  @autobind onAlreadyExists() {
    this.alreadyExists = true;
  }

  render() {
    return (
      <FormWrapper
        padding={0}
        background={this.props.background}
        width={this.props.width}
        borderRadius={this.props.borderRadius}
      >
        {
          this.isButtonOnly ? (
            <Button
              onClick={this.expandFormFields}
              scaleOnHover="1.05"
              height="50px"
              borderRadius="50px"
              width="100%"
              background={AppUI.siteIsDefault ? "#FFF" : AppUI.site.accentColor }
              color={AppUI.siteIsDefault ? Theme.blue : "#FFF"}
              backgroundOnHover="#FFF"
            >
              Register With Email
            </Button>
          ) :
          this.alreadyExists ? (
            <AlreadyExistsNotice
              onClickSignIn={this.props.onClickSignIn}
            />
          ) : (
            <RegistrationForm
              checkout={this.props.checkout}
              showEmailConfirmation={this.props.showEmailConfirmation}
              showTerms={this.props.showTerms}
              hidePrivacy={this.props.hidePrivacy}
              hideTitle={this.props.hideTitle}
              hideSpinner={this.props.hideSpinner}
              color={this.props.color}
              levelId={this.props.levelId}
              languageId={this.props.languageId}
              targetLanguageId={this.props.targetLanguageId}
              onCloseModal={this.props.onCloseModal}
              afterRegistration={this.props.afterRegistration}
              onAlreadyExists={this.onAlreadyExists}

            />
          )
        }
      </FormWrapper>
    );
  }
}

export default AuthenticateRegister;
