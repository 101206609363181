import React from 'react';
import { observer } from 'mobx-react';
import { AppUI, DeckUI } from '@seedlang/state';
import { DeckCloseWebWrapper, PronunciationCardWrapper } from '@seedlang/hoc';
import CardUserInput from 'components/card/card_user_input';
import CardText from 'components/card/card_text';
import { pixify } from '@seedlang/utils';
import MediaWithSupplement from 'components/media/media_with_supplement';
import { DownIcon } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';
import styled from '@emotion/styled';
import EvaluateButtons from 'components/card/evaluate_buttons';
import CardButtons from 'components/card/card_buttons';
import CardButton from 'components/card/card_button';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import { flexCenterColumn } from '@seedlang/style_mixins';
import CardArrowBox from 'components/card/card_arrow_box';

const MediaSection = styled.div`
  width: 100%;
  ${flexCenterColumn()}
  display: ${props => props.display};
`;

const HelpVideo = styled.div`
  height: ${props => props.height};
  position: relative;
`;

const EmptyVideoPlayer = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
  background: #000;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HelpWordWrapper = styled.div`
  width: ${props => props.width};
  background: white;
  padding: 10px;
  border-radius: 10px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HelpWord = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const HelpWordNote = styled.div`
  font-style: italic;
  font-size: 12px;
  margin-top: 5px;
  color: #333;
`;

@observer
class SentencePronunciationCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        showWordCard={this.props.showWordCard}
        icons={this.props.icons}
      >
        <CardContent>
          <MediaSection
            display={!this.props.showHelp && this.props.showVideoWithSupplement ? 'flex' : 'none'}
          >
            <MediaWithSupplement
              autoPlay={this.props.inView && !this.props.showHelp && this.props.showVideoWithSupplement}
              borderTopRadius={10}
              borderBottomRadius={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
              videoClip={ this.props.card.targetVideoClip }
              supplementVideoClip={this.props.card.supplementVideoClip}
              supplementImage={this.props.card.supplementImage}
              usingDataSaverHeader={AppUI.usingDataSaverHeader}
              supplementBottom={DeckUI.layout.supplementBottom}
              loopSupplement={DeckUI.user.loopSupplement && this.props.card.cardRule && this.props.card.cardRule.loopSupplement}
              hideSupplement={!this.props.showDeckSupplement}
              maximizedWidth={pixify(DeckUI.layout.videoHeight)}
              minimizedWidth={pixify(DeckUI.layout.supplementWidth)}
              supplementMaximized={this.props.supplementMaximized}
              loadVideo={!this.props.showHelp}
              marginBottom={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
              afterVideoPlayback={this.props.afterVideoPlayback}
            />
          </MediaSection>
          <CardText
            simpleTextMode={this.props.cardState.revealed}
            card={this.props.card}
            cardState={this.props.cardState}
            hasUserInput
            showHelp={this.props.showHelp}
            padding="0 30px"
            cardId={this.props.card.id}
            sentence={this.props.card.sentence}
            cardTypeSlug={this.props.card && this.props.card.cardTypeSlug}
            showText
            expand={!this.props.showHelp && !this.props.cardState.revealed}
            showType={this.props.cardState.showType}
            borderTopRadius={this.props.cardTextBorderTopRadius}
            borderBottomRadius={this.props.cardTextBorderBottomRadius}
            onClickHelpWord={this.props.onClickHelpWord}
          />
          <CardUserInput
            disabled={!this.props.inView}
            card={this.props.card}
            cardState={this.props.cardState}
          />
          <CardArrowBox
            possibleArrowBoxes={this.props.possibleArrowBoxes}
            currentArrowBox={this.props.currentArrowBox}
          />
        </CardContent>
        {
          this.props.showEvaluationButtons &&
          <EvaluateButtons
            card={this.props.card}
            cardState={this.props.cardState}
            userCard={this.props.userCard}
            onNextClick={this.props.onNextClick}
            advanceCard={this.props.advanceCard}
          />
        }
        {
          !this.props.showEvaluationButtons &&
          <CardButtons>
            <CardButton
              submitOnEnter
              shortcut="1"
              text="Reveal Pronunciation"
              backgroundColor={Theme.orange}
              icon={<DownIcon />}
              onClick={() => DeckUI.reveal(this.props.card, this.props.cardState)}
              disabled={!this.props.inView}
            />

          </CardButtons>
        }
      </CardWrapper>
    );
  }
}

export default DeckCloseWebWrapper(PronunciationCardWrapper(SentencePronunciationCard));
