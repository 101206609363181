import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { AuthenticateStore } from '@seedlang/stores';
import AuthenticateStatus from 'components/authenticate/authenticate_status';
import { ServerStore } from '@seedlang/stores';
import FormWrapper from 'components/authenticate/form_wrapper';
import Text from 'components/text';
import Button from 'components/button/button';
import { isValidEmail } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import { Theme } from '@seedlang/constants';
import Spinner from 'components/spinner';
import { AppUI } from '@seedlang/state';

@observer
class AuthenticateForgotPassword extends React.Component {
  @observable email = '';
  @observable submitting = false;

  @computed get disabled() {
    return !isValidEmail(this.email);
  }

  componentDidMount() {
    ServerStore.clearMessages();
  }

  @autobind onSubmit(e) {
    e.preventDefault();
    if (!this.disabled) {
      this.submitting = true;
      AuthenticateStore.sendResetPasswordInstructions({data: {
        email: this.email,
      }}, this.afterSubmit);
    }
  }

  @autobind afterSubmit() {
    this.submitting = false;
  }

  render() {
    return (
      <FormWrapper
        padding="20px"
      >
        <form onSubmit={this.onSubmit.bind(this)} autoComplete="off">
          <Text
            center
            heading="3"
            margin="0 0 10px 0"
            color={AppUI.siteIsDefault ? "#FFF" : "#333"}
          >
            Forgot Your Password?
          </Text>
          <AuthenticateStatus />
          <div>
            <label htmlFor="user_email">Email</label>
            <input
              required
              autoFocus={this.props.show}
              type="email"
              value={this.email}
              onChange={(el) => this.email = el.target.value}
            />
          </div>
          {
            this.submitting &&
              <Spinner
                background="#FFF"
              />
          }
          {
             !this.submitting &&
              <Button
                margin="10px 0 0 0"
                onClick={this.onSubmit}
                disabled={this.disabled}
                height="50px"
                width="100%"
                borderRadius="50px"
                background="#FFF"
                backgroundOnHover="#FFF"
                color={this.disabled ? "#e4e4e4" : AppUI.site.accentColor || Theme.blue}
              >
                Reset Password
              </Button>
          }
        </form>
      </FormWrapper>
    );
  }
}

export default AuthenticateForgotPassword;
