import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { DailyActivityWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import DailyActivityCalendar from 'components/daily_activity/daily_activity_calendar';
import DailyActivityList from 'components/daily_activity/daily_activity_list';
import { AppUI } from '@seedlang/state';
import { Theme} from '@seedlang/constants';
import DailyActivityHeader from 'components/daily_activity/daily_activity_header';
import DailyActivityFooter from 'components/daily_activity/daily_activity_footer';
import DailyActivityInfo from 'components/daily_activity/daily_activity_info';

const Wrapper = styled.div`
  width: 210px;
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 10px 0;
`;

const Button = styled.div`
  color: ${(props) => props.selected ? "#FFF" : Theme.green};
  background: ${(props) => props.selected ? Theme.green : "#FFF"};
  border: 1px solid ${Theme.green};
  font-size: 11px;
  line-height: 15px;
  padding: 2px 10px;
  border-radius: 25px;
  cursor: pointer;
  margin: 0 2px;
`;

@observer
class DailyActivity extends React.Component {
  @observable showInfo = false;

  render() {
    return (
      <Wrapper>
        <DailyActivityHeader
          {...this.props}
        />
        {
          AppUI.user.xpView === 'calendar' && !this.showInfo &&
            <DailyActivityCalendar
              {...this.props}
              showFlags={AppUI.user.targetLanguagesWithXpCount > 1}
            />
        }
        {
          AppUI.user.xpView !== 'calendar' && !this.showInfo &&
            <DailyActivityList
              {...this.props}
              showFlags={AppUI.user.targetLanguagesWithXpCount > 1}
            />
        }
        {
          this.showInfo &&
            <DailyActivityInfo
              hideInfo={() => this.showInfo = false}
            />
        }
        <DailyActivityFooter
          showInfo={this.showInfo}
          toggleInfo={() => this.showInfo = !this.showInfo}
          {...this.props}
        />
      </Wrapper>
    );
  }
}

export default DailyActivityWrapper(DailyActivity);
