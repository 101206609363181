"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = formatComment;
var _linkText = _interopRequireDefault(require("link-text"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function formatComment(text) {
  return (0, _linkText["default"])(text, {
    target: '_blank'
  }).replace(/(?:\r\n|\r|\n)/g, '<br />').replace(/&#39;/g, "'");
}