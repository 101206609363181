"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = deleteAllCookies;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var name = cookies[i].split("=")[0];
    if (name) {
      _jsCookie["default"].remove(name, {
        path: '/'
      });
    }
  }
}