import React from 'react';
import { observer } from 'mobx-react';
import { VocabSentencesWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { isBlank, isPresent } from '@seedlang/utils';
import Text from 'components/text';
import { AppUI } from '@seedlang/state';
import VocabSentence from 'components/vocab/vocab_sentence';
import Paginator from 'components/paginator';
import Spinner from 'components/spinner';
import SentenceSubmissionIndex from 'components/sentence_submission/sentence_submission_index';
import VocabSentencePopup from 'components/vocab/vocab_sentence_popup';
import VocabTriad from 'components/sentence/vocab_triad';

const Wrapper = styled.div`

`;

const Table = styled.div`
  border-radius: 5px;
  ${flexCenterColumn()}
`;

const SentenceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  cursor: ${(props) => props.isClickable ? 'pointer' : null};
  &:hover {
    background: ${(props) => props.isClickable ? '#ECECEC' : null};
  }
`;

const SentenceRow = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  width: 100%;
  border-bottom: 1px solid #CCC;
`;

const Note = styled.div`
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
  padding-bottom: 10px;
  color: #333;
  font-style: italic;
  text-align: left;
  border-bottom: ${props => props.borderBottom};
`;

@observer
class VocabSentences extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.showVocabTriad && this.props.store.page === 1 &&
            <VocabTriad
              word={this.props.word}
            />
        }
        {
          (this.props.popupSentence || this.props.sentence) &&
            <VocabSentencePopup
              onClickSentence={this.props.onClickSentence}
              popupSentence={this.props.popupSentence}
              onToggleCreateReview={this.props.onToggleCreateReview}
              hasReview={this.props.hasReview}
              wordId={this.props.wordId}
              highlightConceptId={this.props.conceptId}
            />
        }
        {
          !this.props.sentencesLoaded &&
            <Spinner />
        }
        {
          this.props.sentencesLoaded && isBlank(this.props.sentences) && isBlank(this.props.word?.vocabTriadConceptId) &&
            <div>
              <Text>
                There are no sentences for this word.
              </Text>
              <Text
                italic
                fontSize="14px"
              >
                We are adding new sentences every week. Check back later!
              </Text>
            </div>
        }
        {
          isPresent(this.props.sentences) &&
            <Table>
              {
                this.props.sentences.map(item => {
                  return (
                    <SentenceRow
                      key={item.id}
                      flexDirection={AppUI.layout.isMobile ? 'column' : 'row'}
                    >
                      <SentenceWrapper
                        isClickable={item.target?.hasVideoClip}
                      >
                        <VocabSentence
                          wordId={this.props.wordId}
                          highlightConceptId={this.props.conceptId}
                          fontSize={this.props.fontSize}
                          lineHeight={this.props.lineHeight}
                          onClick={item.target?.hasVideoClip ? this.props.onClickSentence : false}
                          sentence={item}
                        />
                      </SentenceWrapper>
                    </SentenceRow>
                  )
                })
              }
            </Table>
        }
        {
          (this.props.sentences.length >= this.props.limit || this.props.store?.page > 1) && this.props.sentencesLoaded &&
            <Paginator
              store={this.props.store}
              queryStrings={{vocab_trainer: true}}
              ids={{wordId: this.props.wordId, conceptId: this.props.conceptId}}
              limit={10}
            />
        }
        {
          this.props.sentencesLoaded && isPresent(this.props.sentences) && !this.props.sentencesHaveVideo &&
            <Note
              borderBottom={this.props.showSentenceSubmissions ? "1px solid #CCC" : null}
            >
                {`There ${this.props.sentences.length > 1 ? 'are' : 'is'} no video recording${this.props.sentences.length > 1 ? 's' : ''} for ${this.props.sentences.length > 1 ? 'these' : 'this'} sentence${this.props.sentences.length > 1 ? 's' : ''} yet. We are adding new videos and sentences every week. Check back later!`}
            </Note>
        }
        {
            this.props.showSentenceSubmissions &&
              <SentenceSubmissionIndex
                showTopBorder={this.props.sentencesLoaded && isBlank(this.props.sentences) && isBlank(this.props.word?.vocabTriadConceptId)}
                expandSentenceSubmissionCreate={this.props.expandSentenceSubmissionCreate}
                wordId={this.props.wordId}
              />
        }
      </Wrapper>
    );
  }
}

export default VocabSentencesWrapper(VocabSentences);
