import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { DeckStore } from '@seedlang/stores';
import Copy from 'components/copy';
import autobind from 'autobind-decorator';
import Button from 'components/button/button';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

const Wrapper = styled.div`

`;

@observer
class ReviewDeckIndex extends React.Component {

  constructor(props) {
    super(props);
    this.loadReviews();
  }

  @autobind updateReviewAfter(id, numberDays) {
    DeckStore.updateReviewAfter({ids: {deckId: id}, data: {number_days: numberDays}}, this.loadReviews);
  }

  @autobind loadReviews() {
    DeckStore.getIndex({queryStrings: {user_id: this.props.userId}});
  }

  render () {
    return (
      <Wrapper>
        <table className="table-wrapper">
          <thead>
            <tr>
              <th width="20" />
              <th>Name</th>
              <th>Language</th>
              <th>Default</th>
              <th>Cards</th>
              <th>Daily Goal</th>
              <th>Due</th>
              <th>Retired</th>
              <th>Filters</th>
              <th>Snooze</th>
            </tr>
          </thead>
          <tbody>
            {
              DeckStore.indexData.map(item => {
                return (
                  <tr
                    key={item.id}
                  >
                    <td>
                      <Copy
                        text={item.id}
                      />
                    </td>
                    <td>
                      {item.name}
                    </td>
                    <td>
                      {item.languageId}
                    </td>
                    <td>
                      <InPlaceCheckbox
                        model="decks"
                        icon="check"
                        field="default_review_deck"
                        id={item.id}
                        value={item.defaultReviewDeck}
                      />
                    </td>
                    <td>
                      {item.reviewCardsCount}
                    </td>
                    <td>
                      {item.dailyGoal}
                    </td>
                    <td>
                      {item.pastDueCount}
                    </td>
                    <td>
                      {item.retiredCount}
                    </td>
                    <td>
                      <div>
                        {`Due: ${item.reviewsFilterDue}`}
                      </div>
                      <div>
                        {`Card Type: ${item.reviewsFilterCardTypeId || ''}`}
                      </div>
                      <div>
                        {`Difficulty: ${item.reviewsFilterDifficultyLabel || ''}`}
                      </div>
                      <div>
                        {`Created At: ${item.reviewsFilterCreatedAtDaysAgo || ''}`}
                      </div>
                    </td>
                    <td>
                      <Button
                        onClick={() => this.updateReviewAfter(item.id, 7)}
                      >
                        7 days
                      </Button>
                      <Button
                        margin="0 0 0 10px"
                        onClick={() => this.updateReviewAfter(item.id, -7)}
                      >
                        -7 days
                      </Button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ReviewDeckIndex);
