import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed, autorun } from 'mobx';
import DemoVideo from 'components/demo_video';
import FlashcardDescription from 'components/landing/flashcard_description';
import TreeDescription from 'components/landing/tree_description';
import VocabTrainerDescription from 'components/landing/vocab_trainer_description';
import { AppUI } from '@seedlang/state';
import PromotedRatings from 'components/rating/promoted_ratings';
import styled from '@emotion/styled';
import { Constants } from '@seedlang/constants';
import Text from 'components/text';
import LandingStrip from 'components/landing/landing_strip';
import SiteComparison from 'components/landing/site_comparison';
import Modal from 'components/modal';
import SeedlangLogo from 'components/seedlang_logo';
import { isPresent, setCookieValue, isBlank } from '@seedlang/utils';
import { Link } from 'react-router';
import { flexCenterColumn, flexCenter } from '@seedlang/style_mixins';
import LanguageOption from 'components/language_option';
import { LanguageIcon } from '@seedlang/icons';

const Wrapper = styled.div`
  width: 100%;
  .gradient-little-waves {
    background-image: url('/images/swirl-left.png'),url('/images/swirl-right.png'), linear-gradient(-134deg, #3BD7C5 0%, #0DAFF6 100%);
    background-position: left bottom, right bottom, left top;
    background-repeat: repeat-x;
    background-size: 50%, 50%, cover;
    overflow: hidden;
  }
  .bubble-description {
    padding: 10px 20px;
    background: white;
    border-radius: 10px;
    a {
      color: black;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 0 5px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff6b;
  width: 55px;
  height: 55px;
  border-radius: 15px;
  margin: 0 10px 0 0;
  border: 3px solid #FFF;
`;

const Content = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  flex: 1;
  margin-top: 20px;
`;

const OptionsWrapper = styled.div`
  ${flexCenter()}
`;

const Hero = styled.div`
  padding: 100px 10px 60px 10px;
  text-align: center;
  background-image: url(/images/wave.png), linear-gradient(-134deg, #3BD7C5 0%, #0DAFF6 100%);
  background-position: center bottom, left top;
  background-repeat: repeat-x;
  background-size: 100%, cover;
  @media (max-width: 600px) {
    padding: 60px 10px 60px 10px;
  }
`;

const PatronMessage = styled.div`
  padding: 0 20px 10px 20px;
  background: white;
  p {
    margin: 0 0 10px 0;
  }
  ol {
    margin: 0 0 10px 40px;
  }
  li {
    margin: 0 0 5px 0;
  }
`;

const LogoWrapper = styled.div`
  margin: 0 auto 10px auto;
  width: 100px;
  height: 100px;
  svg {
    height: 100px;
  }
  .seedlang-logo {
    margin: 0 auto;
    width: 100px;
    height: 100px;
  }
`;

@observer
class LandingDefault extends React.Component {
  @observable showLoading = true;
  @observable experiment;
  @observable showPatronModal = false;
  @observable groupId;

  @computed get linkType() {
    if (isBlank(window.location.search.match("assign_group_id")) && AppUI.layout.isMobile && AppUI.layout.osName === 'iOS') {
      return 'iOS';
    } if (isBlank(window.location.search.match("assign_group_id")) && AppUI.layout.isMobile && AppUI.layout.osName === 'Android') {
      return 'android';
    }
    return "browser";
  }

  constructor(props) {
    super(props);
    this.disposer = autorun(() => {
      if (AppUI.userIsLoaded) {
        if (AppUI.routeStore.routeName === 'landing') {
          AppUI.routeToHome();
        } else {
          this.showLoading = false;
        }
      }
    })
  }

  componentDidMount() {
    let promotion;
    const groupId = window.location.search.match("assign_group_id") && window.location.search.match("assign_group_id").input && isPresent(window.location.search.match("assign_group_id").input.split("=")) ? window.location.search.match("assign_group_id").input.split("=")[1] : null;
    setCookieValue('assign_group_id', groupId)
    this.groupId = groupId;
    if (groupId && groupId === 'f74a4102-d65b-448c-b261-60b6be2c7eca') {
      this.showPatronModal = true;
    }
    if (window.location.search.split("=")[0] === "?pr" && window.location.search.split("=")[1]) {
      promotion = window.location.search.split("=")[1];
    }
    AppUI.createEvent("landing - view page", {promotion_slug: promotion, device: 'web'});
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  render() {
    return (
      <Wrapper>
        {
          this.showPatronModal &&
            <Modal
              width="90%"
              maxWidth="640px"
              showCloseIcon
              onCloseModal={() => this.showPatronModal = false}
            >
              <PatronMessage>
                <LogoWrapper
                  onClick={this.props.onSubmitIntro}
                >
                  <SeedlangLogo />
                </LogoWrapper>
                <p><b>Hello Easy German Patron, welcome to Seedlang!</b></p>

                <p>Seedlang is a website for learning German through interactive flashcards.</p>
                <p>But we also host an exclusive worksheets section that only Patrons of Easy German can see! To access the Easy German worksheets and other Patron extras:</p>
                <ol>
                <li>Register on Seedlang by clicking the German flag.</li>
                <li><b>Important: please use the same email address that you use on Patreon.</b></li>
                </ol>
                <p>If you have any questions, you can use the contact form on the website.</p>
              </PatronMessage>
            </Modal>
        }
        <Hero>
          <div className="container">
            <Text
              center
              bold
              margin="10px 0"
              fontSize="40px"
              lineHeight="40px"
              color="#FFF"
            >
              Start Speaking A New Language
            </Text>
            <Text
              center
              fontSize="30px"
              lineHeight="33px"
              color="#FFF"
            >
              {this.default && 'with fun story-based flashcards.'}
              {!this.default && 'Learn the fun and effective way.'}
            </Text>
            {
              this.linkType === 'browser' &&
                <Content>
                  <OptionsWrapper>
                    {
                      ['DE', 'ES', 'FR'].map(item => {
                        return (
                          <Link
                            to={{name: this.groupId ? 'onboardingLanguageWithGroup' : 'onboardingLanguage', params: {groupId: this.groupId, languageId: item}}}
                            key={item}
                          >
                            <LanguageOption
                              languageId={item}
                            />
                          </Link>
                        )
                      })
                    }
                  </OptionsWrapper>
                </Content>
            }
            {
              (this.linkType === 'iOS') &&
                ['DE', 'ES', 'FR'].map(item => {
                  return (
                    <a href={Constants.APP_STORE_WEB_LINKS['ios'][item]}>
                      <Row>
                        <IconWrapper>
                          <LanguageIcon
                            width="30px"
                            languageId={item}
                          />
                        </IconWrapper>
                        <img src="/images/app_store_white.svg" target="_blank" height="55px" alt="App Store Icon" />
                      </Row>
                    </a>
                  )
                })
            }
            {
              (this.linkType === 'android') &&
                ['DE', 'ES', 'FR'].map(item => {
                  return (
                    <a href={Constants.APP_STORE_WEB_LINKS['android'][item]}>
                      <Row>
                        <IconWrapper>
                          <LanguageIcon
                            width="30px"
                            languageId={item}
                          />
                        </IconWrapper>
                        <img src="/images/play_store_white.png" target="_blank" height="55px" alt="Play Store Icon" />
                      </Row>
                    </a>
                  )
                })
            }
            <DemoVideo
              imageSource={this.default ? "/images/video_thumbnail2.jpg" : "/images/video_cover_ending.jpg"}
            />
          </div>
        </Hero>
        <LandingStrip
          headline="Awaken Your Brain"
          description={<span>We tell stories that are fun, surprising, and memorable. This will help to give context to what you are learning and <b>make learning feel effortless.</b></span>}
        >
          <TreeDescription />
        </LandingStrip>
        <LandingStrip
          lightBlue
          showWave="1"
          headline={<span>Our Flashcards Have <i>Superpowers</i></span>}
          description={<span><b>You've never seen flashcards like these before.</b>  They combine video, speaking practice, and embedded grammar to create a fun and effective learning experience.</span>}
        >
          <FlashcardDescription />
        </LandingStrip>
        <LandingStrip
          blue
          showWave="2"
          headline="Customize Your Learning"
          description={<span>Use our vocab trainer to build flashcard decks tailored to <b>specific vocabulary that you want to learn</b>. Each card is pulled from one of our stories and has all of the fun context that will make this vocabulary easy to remember.</span>}
        >
          <VocabTrainerDescription />
        </LandingStrip>
        <LandingStrip
          gray
          headline="How We are Different"
          description={<span>Seedlang brings <b>many new ideas and innovations</b> to app-based language learning. Here is how we stack up against some notable competitors.</span>}
        >
          <SiteComparison />
        </LandingStrip>
        <LandingStrip
          headline="What our students love about Seedlang!"
        >
          <PromotedRatings
            blockBackground="#e8e8e8"
          />
        </LandingStrip>
        <LandingStrip
          headline="Download our mobile apps to learn on the go."
          headlineColor="#FFF"
          descriptionClassName="bubble-description"
          descriptionMargin="10px 0"
          className='gradient-little-waves'
          margin="0 0 -10px 0"
        >
          {
            ['DE', 'ES', 'FR'].map(item => {
              return (
                <Column
                  key={item}
                >
                  <a href={Constants.APP_STORE_WEB_LINKS['ios'][item]}>
                    <img src={`/images/${item.toLowerCase()}_ios.png`} target="_blank" style={{marginBottom: "5px"}} height="60px" alt="App Store Icon"/>
                  </a>
                  <a href={Constants.APP_STORE_WEB_LINKS['android'][item]}>
                    <img src={`/images/${item.toLowerCase()}_android.png`} style={{marginBottom: "5px"}} target="_blank" height="60px" alt="Play Store Icon"/>
                  </a>
                </Column>
              )
            })
          }
        </LandingStrip>
      </Wrapper>
    );
  }
}

export default LandingDefault;
