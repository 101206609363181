import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from "@seedlang/constants";
import ExerciseEntryButton from "components/exercise/exercise_entry_button";
import { ExerciseUI } from "@seedlang/state";
import Alert from "components/alert";

const Wrapper = styled.div`
  background: whitesmoke;
  padding: 20px;
  height: 100%;
  border: ${(props) => props.inDeck ? "none" : "1px solid #c5c5c5"};
  border-radius: 10px;
  .half-circle {
    background: whitesmoke;
  }
  margin-bottom: 10px;
  display: ${props => props.center ? 'flex' : ''};
  align-items: ${props => props.center ? 'center' : ''};
  justify-content: ${props => props.center ? 'center' : ''};
`;

const GrammarTip = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.hasButtons ? 'space-between' : 'center'};
  flex-direction: ${props => props.flexDirection};
  width: ${props => props.center ? '90%' : ''};
`;

@observer
class ExerciseEntry extends React.Component {

  render() {
    return (
      <Wrapper
        inDeck={this.props.inDeck}
        className='exercise-entry'
        center={this.props.center}
      >
        <RowWrapper
          hasButtons={this.props.showButtons}
          flexDirection={this.props.flexDirection ? this.props.flexDirection : this.props.isMobile ? 'column' : 'row'}
          center={this.props.center}
        >
          <Content>
            {this.props.children}
          </Content>
          {
            this.props.showButtons && this.props.exerciseEntry &&
              <ExerciseEntryButton
                exerciseEntry={this.props.exerciseEntry}
              />
          }
        </RowWrapper>
        {
          this.props.grammarTip && !this.props.inDeck &&
          <GrammarTip>
            <i className='fa fa-lightbulb-o' style={{fontSize: "20px", color: Theme.orange, marginRight: "10px"}}/>
            <div
              dangerouslySetInnerHTML={{ __html: this.props.grammarTip }}
            />
          </GrammarTip>
        }
        {
          this.props.warningMessages?.map((item,index) => {
            return (
              <Alert
                key={`warning-${index}`}
                margin='10px 0 0 0'
                width="100%"
              >
                <div
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              </Alert>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExerciseEntry);
