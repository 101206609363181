import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { AppUI, TreeUI } from '@seedlang/state';
import { ServerStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import { last, noop, random } from 'lodash';
import OnboardingWeb from 'components/onboarding/onboarding_web';
import { Languages, Constants } from '@seedlang/constants';
import { isPresent } from '@seedlang/utils';

@observer
class Onboarding extends React.Component {

  constructor(props) {
    super(props);
    AppUI.levelStore.getIndex();
    AppUI.referrerOptionStore.getIndex();
  }

  componentDidMount() {
    if (this.props.params?.languageId) {
      this.onSubmitTargetLanguageId(this.props.params.languageId);
    }
  }

  @observable selectedName = '';
  @observable seen = [];
  @observable showSpinner = false;
  @observable hasGroup = false;
  @observable levelId;
  @observable currentIndex = 0;
  @observable onlyAskForName = false;
  @observable startedFromIntro = false;
  @observable languagesMessageNum;
  @observable nativeLanguageId = 'EN';
  @observable targetLanguageId;

  @computed get targetLanguageName() {
    if (this.targetLanguageId) {
      return Languages[this.targetLanguageId];
    } return "";
  }

  @computed get triviaTreeNodeId() {
    let levelAbbreviation;
    if (AppUI.targetLanguage?.level?.abbreviation) {
      levelAbbreviation = ['A1', 'A2', 'B1'].includes(AppUI.targetLanguage?.level?.abbreviation) ? AppUI.targetLanguage.level.abbreviation : 'B2';
    } else {
      levelAbbreviation = 'A1';
    }
    return Constants.TRIVIA_TREE_NODES_BY_LEVEL[levelAbbreviation];
  }

  @computed get onboardingComponents() {
    let ary;
    if (!AppUI.siteIsDefault) {
      if (AppUI.routeStore.routeName === "onboarding.password") {
        if (window.location.href.match("from_patreon")) {
          return ["password","migration"];
        }
        return ["password"];
      }
      if (AppUI.routeStore.routeName === "onboarding.migration") {
        return ["migration"];
      }
      return ["registration"];
    } else {
      if (this.neededPassword || AppUI.user.enterNewPassword || AppUI.routeStore.routeName === "onboarding.password") {
        ary = ['password'];
      } else if (["landing", "onboarding.registration", "onboarding.sign_in"].indexOf(AppUI.routeStore.routeName) !== -1) {
        ary = ['registration', 'levelId', 'nativeLanguageId'];
      } else {
        ary = ['targetLanguageId', 'levelId', 'nativeLanguageId', 'registration'];
      }
      ary.push('referrer', 'chooseDeck');
      return ary;
    }
  }

  @computed get registration() {
    return AppUI.userIsLoaded && !AppUI.user.signedOut;
  }

  @computed get show() {
    return this.onboardingComponents[this.currentIndex];
  }

  @computed get waiting() {
    return this.onboardingComponents && this.onboardingComponents.filter(item => this[item]);
  }

  @autobind stepFinished(onboardingType) {
    if (this.seen.indexOf(onboardingType) === -1) {
      this.seen.push(onboardingType);
      this.currentIndex = this.currentIndex + 1;
    }
  }

  @autobind onSubmitLevelId(levelId) {
    this.levelId = levelId;
    let data = {};
    const key = `${AppUI.targetLanguageId.toLowerCase()}_level_id`;
    data[key] = levelId;
    AppUI.authUserStore.update({data: data}, noop);
    if (isPresent(AppUI.targetLanguage)) {
      AppUI.targetLanguage.set('levelId', levelId);
    }
    this.stepFinished('levelId');
    const levelAbbreviation = Constants.LEVELS.find(item => item.id === levelId)?.abbreviation;
    AppUI.createEvent("onboarding - submit level", {level: levelAbbreviation});
  }

  @autobind onSubmitReferrer(referrerOptionId) {
    this.referrerOptionId = referrerOptionId;
    this.stepFinished('referrer');
    if (referrerOptionId) {
      this.updateUser('referrerOptionId', referrerOptionId);
    }
    AppUI.createEvent("onboarding - submit referrer", {referrerOptionId: referrerOptionId});
  }

  @autobind onSubmitNativeLanguageId(languageId) {
    this.nativeLanguageId = languageId;
    this.languagesMessageNum = random(1, 3);
  }

  @autobind onSubmitTargetLanguageId(languageId) {
    this.targetLanguageId = languageId;
    this.stepFinished('targetLanguageId');
    AppUI.createEvent("landing - click language", {language_id: languageId});
    TreeUI.loadTreesFromTargetLanguage(languageId);
  }

  @autobind afterSettingLanguage() {
    this.stepFinished('languageId');
    AppUI.createEvent("onboarding - submit language", {languageId: this.nativeLanguageId});
    if (last(this.onboardingComponents) === "languageId") {
      AppUI.routeToSignedInHome();
    }
  }

  @autobind sendCallbackToLoadUser() {
    this.showSpinner = false;
    AppUI.loadUser(this.afterLoadUser);
  }

  @autobind afterLoadUser(resp) {
    AppUI.setAuthUserStoreShowData(resp);
  }

  @autobind onUpdateName(name) {
    this.selectedName = name;
  }

  @autobind onSubmitIntro() {
    this.stepFinished('intro');
    this.startedFromIntro = true;
    AppUI.setBlockRouteToHome(false);
  }

  @autobind afterSigninAsGuest(resp) {
    this.showSpinner = false;
    this.stepFinished('registration');
    if (resp.success) {
      AppUI.setAuthUserStoreShowData(resp);
    } else {
      this.showSpinner = false;
      ServerStore.setErrorMessage(resp.message);
    }
  }

  @autobind updateUser(field, value) {
    let data = {};
    AppUI.user.set(field, value);
    data[field] = value;
    AppUI.authUserStore.update({data: data}, noop);
  }

  @autobind onSkipRegistration() {
    this.showSpinner = true;
    AppUI.authenticateStore.signInAsGuest({data: {levelId: this.levelId, languageId: this.nativeLanguageId, targetLanguageId: this.targetLanguageId}}, this.afterSigninAsGuest);
  }

  @autobind onSubmitPassword(password) {
    this.neededPassword = true;
    this.showSpinner = true;
    AppUI.authUserStore.update({data: {password: password, password_confirmation: password}}, this.afterSubmitPassword);
  }

  @autobind afterSubmitPassword() {
    this.stepFinished('password');
    this.sendCallbackToLoadUser();
    if (last(this.onboardingComponents) === "password") {
      AppUI.routeToSignedInHome();
    }
  }

  @autobind onSelectLearningPreference(learningPreference) {
    AppUI.set('hideOnboarding', true);
    AppUI.user.set('signedOut', false);
    this.updateUser('learningPreference', learningPreference);
    switch(learningPreference) {
      case 'vocab':
        AppUI.set('vocabWordTypeId', 'd5c5fcef-bb92-48a8-b00b-e7c71cb33068');
        AppUI.routeStore.routeToNamed('vocab.home', {page: 1});
        break;
      case 'trivia':
        AppUI.routeStore.routeToNamed('trivia.home');
        break;
      default:
        AppUI.routeStore.routeToNamed('stories.home');
        break;
    }
    AppUI.createEvent("onboarding - submit learning preference", {learning_preference: learningPreference});
  }

  @autobind routeToDeck(learningPreference) {
    switch(learningPreference) {
      case 'none':
        AppUI.routeStore.routeToNamed('reviews.home');
        break;
      case 'conjugation':
        this.onCreateConjugationDeck();
        break;
      case 'vocab':
        AppUI.set('vocabWordTypeId', 'd5c5fcef-bb92-48a8-b00b-e7c71cb33068');
        this.onCreateVocabDeck();
        break;
      case 'trivia':
        this.onCreateTriviaDeck();
        break;
      default:
        const userLevel = ['A1', 'A2'].includes(AppUI.targetLanguage.levelAbbreviation) ? AppUI.targetLanguage.levelAbbreviation : 'B1';
        const chosenDeck = Constants.CHOOSE_DECK_LINKS[AppUI.targetLanguageId][userLevel]
        AppUI.routeStore.routeToNamed('tree_nodes.decks.show', {treeNodeId: chosenDeck['treeNodeId'], deckId: chosenDeck['deckId']});
        break;
    }
  }

  @autobind onCreateVocabDeck() {
    this.showSpinner = true;
    let data = {vocab: true, root: true, video_clip_id: true, max_word: 5, language_id: AppUI.targetLanguageId, filters: {level_id: AppUI.targetLanguage.levelId}};
    AppUI.deckStore.create({data: data}, this.afterCreateVocabDeck);
  }

  @autobind onCreateConjugationDeck() {
    this.showSpinner = true;
    let data = {conjugation: true, root: true, video_clip_id: true, max_word: 5, language_id: AppUI.targetLanguageId, filters: {level_id: AppUI.targetLanguage.levelId}};
    AppUI.deckStore.create({data: data}, this.afterCreateConjugationDeck);
  }

  @autobind afterCreateVocabDeck(resp) {
    this.showSpinner = false;
    AppUI.user.set('freeVocabDecksAvailableCount', 0);
    AppUI.routeStore.routeToNamed('vocab.decks.show', {deckId: resp.id})
  }

  @autobind afterCreateConjugationDeck(resp) {
    this.showSpinner = false;
    AppUI.user.set('freeConjugationDecksAvailableCount', 0);
    AppUI.routeStore.routeToNamed('vocab.decks.show', {deckId: resp.id})
  }

  @autobind onCreateTriviaDeck() {
    this.showSpinner = true;
    AppUI.treeNodeStore.chooseDeck({ids: {treeNodeId: this.triviaTreeNodeId}}, this.afterChooseTriviaDeck);
  }

  @autobind afterChooseTriviaDeck(resp) {
    this.showSpinner = true;
    AppUI.routeStore.routeToNamed('trivia.tree_nodes.decks.show', {treeNodeId: this.triviaTreeNodeId, deckId: resp.deckId});
  }

  @autobind routeToSection(learningPreference) {
    switch(learningPreference) {
      case 'vocab':
        AppUI.set('vocabWordTypeId', 'd5c5fcef-bb92-48a8-b00b-e7c71cb33068');
        AppUI.routeStore.routeToNamed('vocab.home', {page: 1});
        break;
      case 'trivia':
        AppUI.routeStore.routeToNamed('trivia.home');
        break;
      default:
        AppUI.routeStore.routeToNamed('stories.home');
    }
  }

  @autobind afterRegistration() {
    this.stepFinished('registration');
    if (last(this.onboardingComponents) === "registration") {
      AppUI.routeToSignedInHome();
    }
  }

  render() {
    if (!AppUI.loadingUser) {
      return (
        <OnboardingWeb
          user={AppUI.user}
          event={AppUI.event}
          onSubmitLevelId={this.onSubmitLevelId}
          onSubmitNativeLanguageId={this.onSubmitNativeLanguageId}
          onSubmitTargetLanguageId={this.onSubmitTargetLanguageId}
          onSubmitReferrer={this.onSubmitReferrer}
          levels={AppUI.levelStore.indexData}
          referrerOptions={AppUI.referrerOptionStore.indexData.filter(item => item.languageId === AppUI.targetLanguageId)}
          show={this.show}
          isMobile={AppUI.layout.isMobile}
          purposeOfAccount={this.purposeOfAccount}
          onUpdateName={this.onUpdateName}
          selectedName={this.selectedName}
          onSubmitIntro={this.onSubmitIntro}
          waiting={this.waiting}
          seen={this.seen}
          showSpinner={this.showSpinner}
          afterRegistration={this.afterRegistration}
          onSkipRegistration={this.onSkipRegistration}
          onSubmitPassword={this.onSubmitPassword}
          onSelectLearningPreference={this.onSelectLearningPreference}
          hideGuestSignIn={this.hasGroup}
          showSignIn={["landing", "onboarding.sign_in"].includes(AppUI.routeStore.routeName)}
          afterSettingLanguage={this.afterSettingLanguage}
          languagesMessageNum={this.languagesMessageNum}
          stepFinished={this.stepFinished}
          targetLanguageId={this.targetLanguageId}
          nativeLanguageId={this.nativeLanguageId}
          levelId={this.levelId}
          targetLanguageIds={this.targetLanguageIds}
          targetLanguageName={this.targetLanguageName}
        />
      );
    }
    return <span />
  }
}

export default Onboarding;
