import React from 'react';
import { observer } from 'mobx-react';
import ListSelect from 'components/list_select';
import Text from 'components/text';
import styled from '@emotion/styled';
import SlideWrapper from 'components/hoc/slide_wrapper.js';
import { flexCenterColumn } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  width: 100%;
  ${flexCenterColumn()}
  height: 100%;
`;

const Content = styled.div`
  ${flexCenterColumn()}
  width: 100%;
`;

@observer
class OnboardingLevel extends React.Component {

  render() {
    return (
      <Wrapper>
        <Content>
          <Text
            center
            heading="3"
            margin="0 0 20px 0"
            color="#FFF"
          >
            What is your {this.props.targetLanguageName} Level?
          </Text>
          <ListSelect
            options={this.props.levels}
            onSelect={this.props.onSubmitLevelId}
            fieldName="nameWithAbbreviation"
          />
        </Content>
      </Wrapper>
    );
  }
}

export default SlideWrapper(OnboardingLevel);
