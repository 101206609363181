import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Motion, spring } from 'react-motion';
import { CloseIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import { pixify } from '@seedlang/utils';
import Arrow from 'components/svg/arrow';
import { ArrowBoxWrapper } from '@seedlang/hoc';
import { flexCenter } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  display: ${(props) => props.display};
  transform: ${(props) => props.transform};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  border: ${(props) => props.border};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  width: ${(props) => props.width};
  z-index: ${(props) => props.zIndex};
  position: ${(props) => props.position};
  margin: ${(props) => props.margin};
  background: ${(props) => props.background};
  border-radius: 10px;
  text-align: center;
  color: black;
`;

const InnerWrapper = styled.div`
  ${flexCenter()}
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const ArrowWrapper = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottom};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  svg {
    transform: ${(props) => props.transform};
    fill: ${props => props.arrowFill};
  }
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  z-index: ${(props) => props.zIndex};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  svg {
    fill: ${(props) => props.fill};
    width: ${(props) => props.width};
  }
`;

const STIFFNESS = 210;
const DAMPING = 20;

@observer
class ArrowBox extends React.Component {
  @observable hide = false;

  static defaultProps = {
    arrowPosition: 'bottom',
    padding: '15px',
    position: 'absolute',
    margin: '0 auto',
    closeIconTop: "8px",
    closeIconRight: "5px",
    closeIconFill: "#000",
    closeIconWidth: "20px",
    border: "1px solid #CCC",
    width: "100%",
    height: "100%",
    arrowFill: "#FFF",
    arrowBoxBackground: "#FFF",
  }

  @observable scale = 0;

  constructor(props) {
    super(props);
    if (this.props.show) {
      this.scale = 1;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      if (!this.props.show) {
        this.hide = true;
        if (this.props.onClose) {
          this.props.onClose();
        }
        setTimeout(() => this.hide = false, 500);
      }
      this.scale = this.props.show ? 1 : 0;
    }
  }

  render() {
    return (
      <Motion
        style={{
          scale: spring(this.scale, {stiffness: STIFFNESS, damping: DAMPING}),
        }}
      >
        {
          ({scale}) =>
            <Wrapper
              display={this.hide ? 'none' : 'flex'}
              width={pixify(this.props.width)}
              left={pixify(this.props.left)}
              right={pixify(this.props.right)}
              top={pixify(this.props.top)}
              bottom={pixify(this.props.bottom)}
              zIndex={this.props.zIndex}
              transform={`scale(${scale})`}
              fontSize={this.props.fontSize}
              lineHeight={this.props.lineHeight}
              position={this.props.position}
              margin={this.props.margin}
              border={this.props.border}
              closeIconTop={this.props.closeIconTop}
              closeIconRight={this.props.closeIconRight}
              closeIconFill={this.props.closeIconFill}
              height={pixify(this.props.height)}
              onClick={this.props.enableClickOnWrapper && this.props.onClick}
              background={this.props.arrowBoxBackground}
            >
              <InnerWrapper
                padding={this.props.padding}
                width={pixify(this.props.width)}
                height={pixify(this.props.height)}
              >
                {
                  this.props.onClick && !this.props.hideCloseIcon &&
                    <CloseIconWrapper
                      top={this.props.closeIconTop}
                      right={this.props.closeIconRight}
                      zIndex={this.props.zIndex}
                      fill={this.props.closeIconFill}
                      width={this.props.closeIconWidth}
                    >
                      <CloseIcon
                        width={this.props.closeIconWidth}
                        height={this.props.closeIconWidth}
                        onClick={this.props.onClick}
                      />
                    </CloseIconWrapper>
                }
                {this.props.children}
                <ArrowWrapper
                  top={pixify(this.props.arrowPositionTop)}
                  bottom={pixify(this.props.arrowPositionBottom)}
                  left={pixify(this.props.arrowPositionLeft)}
                  right={pixify(this.props.arrowPositionRight)}
                  transform={this.props.arrowTransform}
                >
                  <Arrow
                    width="20px"
                    fill={this.props.arrowFill}
                  />
                </ArrowWrapper>
              </InnerWrapper>
            </Wrapper>
        }
      </Motion>
    );
  }
}

export default ArrowBoxWrapper(ArrowBox);
