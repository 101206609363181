import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import {
  ConceptStore,
  GrammarCaseStore,
  GrammarTenseStore,
  LevelStore,
  WordStore,
  WordTypeStore
} from '@seedlang/stores';
import { Link } from 'react-router';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceFlatSelect from 'components/form/in_place_flat_select';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import RootWordEdit from 'pages/builder/words/root_word_edit';
import ConceptSelect from 'pages/builder/concepts/concept_select';
import ConceptCheckbox from 'pages/builder/concepts/concept_checkbox';
import DeleteButton from 'components/button/delete_button';
import cx from 'classnames';
import { isBlank, isPresent } from '@seedlang/utils';
import WordSourceEdit from 'pages/builder/sources/word_source_edit';
import WordAssociationSmartyReport from 'pages/builder/word_associations/word_association_smarty_report';
import autobind from 'autobind-decorator';
import PluralEdit from 'pages/builder/words/plural_edit';
import ReactTooltip from 'react-tooltip';
import GenderVariantEdit from "./gender_variant_edit";
import { AppUI } from '@seedlang/state';

const Hover = styled.div`
  font-size: 12px;
  border: 1px dotted transparent;
  &:hover {
    border: 1px dotted #CCC;
  }
`;

@observer
class WordIndexRow extends React.Component {

  @observable selectedLevel;
  @observable showEditWarning;

  @computed get invalid() {
    if (!this.props.word) { return null }
    return (this.props.scopedToSentence && this.props.word.root) || this.props.word.invalid || this.invalidPlural;
  }

  @computed get invalidPlural() {
    return this.props.word.isNoun && !this.props.word.plural && !this.props.word.noPlural && isBlank(this.props.word.pluralNoun);
  }

  conceptsLength(item) {
    return isBlank(item.concepts) ? 0 : item.concepts.length;
  }

  @autobind onChangeWordType(wordTypeId) {
    WordStore.update({ids: {wordId: this.props.word.id}, data: {word_type_id: wordTypeId}}, this.props.onChange);
  }

  @computed get level() {
    return this.selectedLevel || this.props.word.level;
  }

  setLevel(levelId) {
    const level = LevelStore.indexData.find(item => item.id === levelId);
    if (level) {
      WordStore.update({ids: {wordId: this.props.word.id}, data: {level_id: level.id}}, this.props.afterChangeLevel);
      this.selectedLevel = level;
    }
  }

  @autobind removeIsPlural() {
    WordStore.update({ids: {wordId: this.props.word.id}, data: {plural: false}}, this.props.onChange);
  }

  @computed get levelOptions() {
    if (!LevelStore.hasIndexData) { return }
    const options = LevelStore.indexData.filter(level => isPresent(level.abbreviation) && level.abbreviation !== 'N').map(level => [level.id, level.abbreviation]);
    if (this.props.word.wordType?.name === 'Number') {
      return options.slice(0,2);
    } return options;
  }


  render() {
    if (this.props.word) {
      return (
        <tr
          className={cx('word-index-row', {
            invalid: this.invalid,
          })}
        >
          {
            this.props.word &&
              <td>
                <div
                  data-for={`word-page-${this.props.word.id}`}
                  data-tip
                >
                  <Link
                    to={{ name: 'builder.words.edit', params: { wordId: this.props.word.id } }}
                    className="on-click"
                  >
                    <i className="fa fa-chevron-right fa-block" />
                  </Link>
                  <ReactTooltip
                    place="top"
                    type="dark"
                    effect="solid"
                    id={`word-page-${this.props.word.id}`}
                    class="custom-tooltip"
                  >
                    Go to Word Page
                  </ReactTooltip>
                </div>
              </td>
          }
          <td>
            { this.invalid &&
              <div
                data-for={`warning-${this.props.word.id}`}
                data-tip
              >
                <i className="fa fa-warning fa-row-warning" />
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="solid"
                  id={`warning-${this.props.word.id}`}
                  class="custom-tooltip"
                >
                  {
                    (this.props.scopedToSentence && this.props.word.root) &&
                      <div>Root word shouldn't be in sentence</div>
                  }
                  {
                    this.invalidPlural &&
                      <div>Invalid plural</div>
                  }
                  {
                    this.props.word.invalidWordType &&
                      <div>Invalid word type</div>
                  }
                  {
                    this.props.word.invalidSource &&
                      <div>Invalid source</div>
                  }
                  {
                    this.props.word.invalidRoot &&
                      <div>Invalid root</div>
                  }
                  {
                    this.props.word.invalidLevel &&
                      <div>Missing level</div>
                  }
                  {
                    this.props.word.invalidGender &&
                      <div>Invalid gender</div>
                  }
                  {
                    this.props.word.invalidInfinitive &&
                      <div>Invalid infinitive</div>
                  }
                  {
                    this.props.word.invalidTense &&
                      <div>Missing tense</div>
                  }
                  {
                    this.props.word.invalidCase &&
                      <div>Missing case</div>
                  }
                  {
                    this.props.word.invalidPrefix &&
                      <div>Missing prefix</div>
                  }
                </ReactTooltip>
              </div>
            }
            {
              this.props.scopedToSentence && this.props.word &&
                <span
                  data-for={`swap-word-${this.props.word.id}`}
                  data-tip
                >
                  <div className="icon-wrapper" style={{marginRight: 5}}>
                    <div className="encircle-icon swap-words">
                      <i
                        className="fa fa-exchange fa-on-click"
                        onClick={() => this.props.showSwapWordAssociation(this.props.wordAssociation)}
                      />
                    </div>
                  </div>
                  <ReactTooltip
                    place="top"
                    type="dark"
                    effect="solid"
                    id={`swap-word-${this.props.word.id}`}
                    class="custom-tooltip"
                  >
                    Swap Word Form
                  </ReactTooltip>
                </span>
            }
            {
              this.props.scopedToSentence && 
                <span
                  data-for={`view-concepts-${this.props.word.id}`}
                  data-tip
                >
                  <div className="icon-wrapper" style={{marginRight: 5}}>
                    <div
                      className="encircle-icon"
                      onClick={() => this.props.showConcepts(this.props.word, this.props.wordAssociation)}
                    >
                      <i className="fa fa-lightbulb-o" />
                    </div>
                    <div className={cx('count', { populated: this.conceptsLength(this.props.word) > 0, hide: this.conceptsLength(this.props.word) === 0 })}>
                      {this.props.word.concepts.length}
                    </div>
                  </div>
                  <ReactTooltip
                    place="top"
                    type="dark"
                    effect="solid"
                    id={`view-concepts-${this.props.word.id}`}
                    class="custom-tooltip"
                  >
                    View Concepts
                  </ReactTooltip>
                </span>
            }
            <span
              data-for={`external-info-${this.props.word.id}`}
              data-tip
            >
              <div className="icon-wrapper">
                <a
                  href={this.props.word.dictionaryUrl}
                  target="seedlang"
                  className="no-underline no-hover"
                >
                  <div className="encircle-icon">
                    <i className="fa fa-external-link" />
                  </div>
                </a>
              </div>
            </span>
            <ReactTooltip
              place="top"
              type="dark"
              effect="solid"
              id={`external-info-${this.props.word.id}`}
              class="custom-tooltip"
            >
              External Info
            </ReactTooltip>
          </td>
          {
            !this.props.scopedToSentence &&
              <td>
                <div className="text" style={{width: 30}}>
                  <span data-tip data-for={`didactics-${this.props.word.id}`} style={{marginRight: 4}}>
                    <InPlaceCheckbox
                      model="words"
                      field="reviewed2"
                      value={this.props.word.reviewed2}
                      id={this.props.word.id}
                      icon="thumbs-up"
                      afterChange={this.afterChange}
                    />
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      id={`didactics-${this.props.word.id}`}
                      class="custom-tooltip"
                    >
                      Approved: Didactics
                    </ReactTooltip>
                  </span>
                </div>
              </td>
          }
          {
            !this.props.scopedToSentence &&
              <td>
                { this.props.word.frequencyRanking }
              </td>
          }
          {
            !this.props.scopedToSentence &&
              <td>
                { this.props.word.sentencesCount }
              </td>
          }
          {
            !this.props.scopedToSentence &&
              <td>
                { this.props.word.treePosition }
              </td>
          }
          {
            !this.props.scopedToSentence &&
              <td>
                { this.props.word.languageId }
              </td>
          }
          {
            this.props.hasSmartyParallelTokenisation &&
              <td>
                <WordAssociationSmartyReport
                  wordAssociation={this.props.wordAssociation}
                  smartyWordAssociation={this.props.smartyWordAssociation}
                />
              </td>
          }
          <td>
            <div className="text">
              {
                isPresent(this.props.word.wordType) && this.props.word.wordType.id &&
                  <Link
                    to={{name: 'builder.word_types.edit', params: {wordTypeId: this.props.word.wordType.id}}}
                  >
                    {this.props.word.wordType.abbreviation}
                  </Link>
              }
              {
                !isPresent(this.props.word.wordType) &&
                  <InPlaceSelect
                    includeBlank
                    style={{ width: 40 }}
                    options={WordTypeStore.indexData.filter(item => item.wordOption && item.languagesString?.match(this.props.word.languageId)).map(item => [item.id, item.name])}
                    onChange={this.onChangeWordType}
                  />
              }
            </div>
          </td>
          <td>
            <div
              className="text"
              style={{ width: 100 }}
            >
              {
                this.props.scopedToSentence && isPresent(this.props.word?.target) &&
                  <div>
                    <Link
                      className='target-link'
                      to={{name: 'builder.targets.edit', params: {targetId: this.props.word.target.id}}}
                    >
                      {this.props.word.targetText}
                    </Link>
                  </div>
              }
              {
                (!this.props.scopedToSentence || !isPresent(this.props.word?.target)) &&
                  <div>{this.props.word.targetText}</div>
              }
            </div>
          </td>
          <td>
            <WordSourceEdit
              wordAssociation={this.props.wordAssociation}
              word={this.props.word}
              onChange={this.props.onChange}
              scopedToSentence={this.props.scopedToSentence}
            />
          </td>
          {
            !this.props.hideCase && this.props.scopedToSentence &&
              <td>
                <div className='text'>
                  {
                    !this.props.word.root && (GrammarCaseStore.hasIndexData && this.props.word.wordType && (this.props.word.wordType.requiresCase)) &&
                      <ConceptSelect
                        word={this.props.word}
                        afterUpdate={this.props.onChange}
                        optionName='grammarCase'
                        concepts={GrammarCaseStore.indexData.filter(gc => gc.languageId === this.props.word.languageId && this.props.word.wordType && gc.wordTypeId === this.props.word.wordType.id)}
                      />
                  }
                </div>
              </td>
          }
          {
            !this.props.hideTense && this.props.scopedToSentence &&
              <td>
                <div className='text'>
                  {
                    GrammarTenseStore.hasIndexData && this.props.word.wordType && this.props.word.wordType.name === 'Verb' && !this.props.word.root &&
                      <ConceptSelect
                        word={this.props.word}
                        afterUpdate={this.props.onChange}
                        optionName='grammarTense'
                        concepts={GrammarTenseStore.indexData.filter(gt => gt.languageId === this.props.word.languageId)}
                      />
                  }
                </div>
              </td>
          }
          <td>
            {
              this.props.word.root &&
                <i className='fa fa-check' />
            }
            {
              !this.props.word.root &&
                <div className="word-attribute select parent-verb text">
                  <RootWordEdit
                    blockEdit
                    word={this.props.word}
                    onChange={this.props.onChange}
                  />
                </div>
            }
          </td>
          <td>
            {
              this.props.word.plural &&
                <Hover
                  onClick={this.removeIsPlural}
                >
                  <i className='fa fa-check' /> Is Plural
                </Hover>
            }
            {
              (this.props.word.root || this.props.scopedToSentence || !AppUI.siteIsDefault) && !this.props.word.plural && this.props.word.wordType && this.props.word.wordType.name === 'Noun' &&
                <PluralEdit
                  word={this.props.word}
                  wordAssociation={this.props.wordAssociation}
                  onChange={this.props.onChange}
                />
            }
          </td>
          {
            WordStore.filters['language_id'] !== 'DE' && !this.props.scopedToSentence && !this.props.hideGenderVariant &&
            <td>
              {
                this.props.word.root && !this.props.word.plural && this.props.word.wordType?.name && (this.props.word.wordType.name.startsWith('Adjective') || this.props.word.isNoun || this.props.word.isNumber) &&
                  <GenderVariantEdit
                    word={this.props.word}
                    placeholder=" "
                    displayField="targetWithGenderAndSentencesCount"
                    onChange={this.props.onChange}
                    border={this.props.word.isNoun ? 'none' : null}
                  />
              }
            </td>
          }
          <td>
            {
              this.props.word.wordType && this.props.word.wordType.name === 'Noun' && this.props.genderOptions &&
                <span>
                  <div className="word-attribute select">
                    {
                      this.props.word.gender
                    }
                    {
                      !this.props.word.gender &&
                        <InPlaceFlatSelect
                          options={this.props.genderOptions}
                          model="words"
                          field="gender"
                          afterChange={this.props.onChange}
                          value={this.props.word.gender}
                          id={this.props.word.id}
                        />
                    }
                  </div>
                </span>
            }
            {
              this.props.scopedToSentence && this.props.word.wordType && ConceptStore.hasIndexData &&
                ConceptStore.filterWordType(this.props.word.wordType.id).filter(item => item.languageId === this.props.word.languageId).map((concept) => {
                  return (
                    <div
                      className="word-attribute checkbox"
                      key={concept.id}
                    >
                      <ConceptCheckbox
                        concept={concept}
                        afterUpdate={this.props.onChange}
                        word={this.props.word}
                        label={concept.abbreviation}
                        tooltip={concept.name}
                      />
                    </div>
                  );
                })
            }
            {
              !this.props.word.root && this.props.word.wordType && this.props.word.wordType.name === 'Verb' && this.props.word.isSeparable && !this.props.word.isStem &&
                <span
                  data-for={`prefix-${this.props.word.id}`}
                  data-tip
                >
                  <InPlaceCheckbox
                    wrapperMargin="0"
                    hideCheckbox
                    model="words"
                    field="is_prefix"
                    value={this.props.word.isPrefix}
                    id={this.props.word.id}
                    afterChange={this.props.onChange}
                  >
                    Pre
                  </InPlaceCheckbox>
                  <ReactTooltip
                    place="top"
                    type="dark"
                    effect="solid"
                    id={`prefix-${this.props.word.id}`}
                    class="custom-tooltip"
                  >
                    Prefix of separable verb
                  </ReactTooltip>
                </span>
            }
            {
              !this.props.word.root && this.props.word.wordType && this.props.word.wordType.name === 'Verb' && this.props.word.isSeparable && !this.props.word.isPrefix &&
                <span
                  data-for={`stem-${this.props.word.id}`}
                  data-tip
                >
                  <InPlaceCheckbox
                    wrapperMargin="0"
                    hideCheckbox
                    model="words"
                    field="is_stem"
                    value={this.props.word.isStem}
                    id={this.props.word.id}
                    afterChange={this.props.onChange}
                  >
                    Stm
                  </InPlaceCheckbox>
                  <ReactTooltip
                    place="top"
                    type="dark"
                    effect="solid"
                    id={`stem-${this.props.word.id}`}
                    class="custom-tooltip"
                  >
                    Stem of separable verb
                  </ReactTooltip>
                </span>
            }
            {
              !this.props.word.root && this.props.word.wordType && this.props.word.wordType.name === 'Verb' && this.props.word.isSeparable && this.props.word.isStem &&
                <div className='prefix-wrapper'>
                  {
                    this.props.word.hasPrefix &&
                      <div
                        style={{margin: "1px 0 0 3px"}}
                        className='prefix'
                        onClick={() => WordStore.update({data: {prefix_id: null}, ids: {wordId: this.props.word.id}}, this.props.onChange)}
                      >
                        <div className='text'>
                          Pre: {this.props.word.prefix.targetText}
                        </div>
                        <div className='remove'>
                          Remove
                        </div>
                      </div>
                  }
                  {
                    !this.props.word.hasPrefix &&
                      <span
                        data-for={`connect-${this.props.word.id}`}
                        data-tip
                      >
                        <i
                          className='fa fa-download fa-on-click'
                          onClick={() => WordStore.assignPrefix({ids: {wordId: this.props.word.id}}, this.props.onChange)}
                        />
                        <ReactTooltip
                          place="top"
                          type="dark"
                          effect="solid"
                          id={`connect-${this.props.word.id}`}
                          class="custom-tooltip"
                        >
                          Connect Prefix and Stem
                        </ReactTooltip>
                      </span>
                  }
                </div>
            }
          </td>
          {
            (!this.props.scopedToSentence || this.props.word.root) &&
              <td>
                {
                  this.props.word.hasAssignableRoot &&
                    <span
                      data-for={`exclude-1-${this.props.word.id}`}
                      data-tip
                    >
                      <InPlaceCheckbox
                        model="words"
                        field="exclude_from_public_lists"
                        value={this.props.word.excludeFromPublicLists}
                        id={this.props.word.id}
                        afterChange={this.props.afterChange}
                        icon="check"
                      />
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="solid"
                        id={`exclude-1-${this.props.word.id}`}
                        class="custom-tooltip"
                      >
                        Exclude from Vocab List
                      </ReactTooltip>
                    </span>
                }
              </td>
          }
          {
            WordStore.filters["word_type_id"] && WordStore.filters["word_type_id"].length === 1 && WordStore.filters["word_type_id"][0] === '1a0d84ff-a903-4be9-b0a8-22fdcc211506' && !this.props.scopedToSentence &&
              <td>
                <InPlaceCheckbox
                  model="words"
                  field="is_date"
                  value={this.props.word.isDate}
                  id={this.props.word.id}
                  afterChange={this.props.afterChange}
                  icon="check"
                />
              </td>
          }
          {
            this.props.scopedToSentence && !this.props.word.root &&
              <td>
                {
                  this.props.word.rootWord && this.props.word.hasAssignableRoot &&
                    <span
                      data-for={`exclude-2-${this.props.word.id}`}
                      data-tip
                    >
                      <InPlaceCheckbox
                        model="words"
                        field="exclude_from_public_lists"
                        value={this.props.word.rootWord.excludeFromPublicLists}
                        id={this.props.word.rootWord.id}
                        afterChange={this.afterChange}
                        icon="check"
                      />
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="solid"
                        id={`exclude-2-${this.props.word.id}`}
                        class="custom-tooltip"
                      >
                        Exclude Root from Vocab List
                      </ReactTooltip>
                    </span>
                }
              </td>
          }
          <td>
            {
              LevelStore.hasIndexData &&
                <InPlaceFlatSelect
                  options={this.levelOptions}
                  model="words"
                  field="levelId"
                  onChange={(newValue) => this.setLevel(newValue)}
                  value={this.level ? this.level.id : null}
                  id={this.props.word.id}
                />
            }
          </td>
          {
            !this.props.scopedToSentence &&
              <td>
                <DeleteButton
                  onConfirm={() => this.props.onDelete(this.props.word.id)}
                  message={this.props.deleteMessage || "Delete this word?"}
                  className="fa-block right"
                />
              </td>
          }
        </tr>
      );
    }
    return <div />
  }
}

export default ErrorBoundary(WordIndexRow);
