import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
// don't forget SlideWrapper around these components
import OnboardingLevel from 'components/onboarding/onboarding_level';
import OnboardingNativeLanguage from 'components/onboarding/onboarding_native_language';
import OnboardingTargetLanguage from 'components/onboarding/onboarding_target_language';
import OnboardingRegistration from 'components/onboarding/onboarding_registration';
import OnboardingPassword from 'components/onboarding/onboarding_password';
import OnboardingReferrer from 'components/onboarding/onboarding_referrer';
import OnboardingIntro from 'components/onboarding/onboarding_intro';
import OnboardingChooseDeck from 'components/onboarding/onboarding_choose_deck';
import Backdrop from 'components/backdrop';
import { Theme } from '@seedlang/constants';
import { flexCenterColumn, fixedCenter, absoluteCenter } from '@seedlang/style_mixins';
import Spinner from 'components/spinner';
import { AppUI } from '@seedlang/state';
import isBlank from "is-blank";
import OnboardingMigration from "components/onboarding/onboarding_migration";
import { computed } from "mobx";
import { isPresent } from "@seedlang/utils/src";

const Wrapper = styled.div`
  ${fixedCenter()}
  background: ${props => props.background};
  z-index: ${Theme.z.foreground};
  .title {
    margin-bottom: 20px;
    text-align: center;
  }
  .onboarding-spinner {
    position: absolute;
    top: 0;
    z-index: 999;
  }
`;

const Onboarding = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${absoluteCenter()}
  background: ${props => props.background};
  border-radius: ${(props) => props.isMobile ? 0 : '20px'};
  max-width: ${(props) => props.isMobile ? '100%' : '500px'};
  max-height: ${(props) => props.maxHeight};
  width: ${(props) => props.isMobile ? '100%' : 'auto'};
  max-height: ${(props) => props.isMobile ? '100%' : props.isDefault ? '500px' : '580px'};
  margin: ${(props) => props.isMobile ? '0' : '60px auto'};
  width: 100%;
  z-index: 4;
  padding: 20px;
  overflow: auto;
  @media only screen and (max-width : 649px) {
    padding: 0;
  }
`;

const OnboardingInner = styled.div`
  ${flexCenterColumn()}
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 20px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;

@observer
class OnboardingWeb extends React.Component {

  render() {
    return (
      <Wrapper
        background={AppUI.siteIsDefault || isBlank(AppUI.site?.footerBackgroundColor) ? '#044067' : AppUI.site?.footerBackgroundColor}
      >
        {
          !this.props.isMobile &&
            <Backdrop
              percentageComplete={(this.currentIndex/3) * 20}
            />
        }
        <Onboarding
          className='onboarding'
          isMobile={this.props.isMobile}
          isDefault={AppUI.siteIsDefault}
          background={AppUI.siteIsDefault ? 'linear-gradient(-134deg,#3BD7C5 0%,#0DAFF6 100%)' : '#fff' }
        >
          <OnboardingInner
            isMobile={this.props.isMobile}
          >
            {
              this.props.showSpinner &&
                <Spinner
                  background="white"
                />
            }
            <OnboardingPassword
              show={this.props.show === 'password'}
              waiting={this.props.seen.indexOf('password') !== -1}
              seen={this.props.seen.indexOf('password') !== -1}
              showSpinner={this.props.showSpinner}
              onSubmitPassword={this.props.onSubmitPassword}
            />
            <OnboardingMigration
              show={this.props.show === 'migration'}
              waiting={this.props.seen.indexOf('migration') !== -1}
              seen={this.props.seen.indexOf('migration') !== -1}
              showSpinner={this.props.showSpinner}
            />
            <OnboardingLevel
              show={this.props.show === 'levelId'}
              waiting={this.props.seen.indexOf('levelId') !== -1}
              seen={this.props.seen.indexOf('levelId') !== -1}
              levels={this.props.levels}
              onSubmitLevelId={this.props.onSubmitLevelId}
              targetLanguageName={this.props.targetLanguageName}
            />
            <OnboardingTargetLanguage
              languageId={this.props.targetLanguageId}
              targetLanguageIds={this.targetLanguageIds}
              show={this.props.show === 'targetLanguageId'}
              waiting={this.props.seen.indexOf('targetLanguageId') !== -1}
              seen={this.props.seen.indexOf('targetLanguageId') !== -1}
              onSubmitTargetLanguageId={this.props.onSubmitTargetLanguageId}
              afterSettingLanguage={this.props.afterSettingLanguage}
              languagesMessageNum={this.props.languagesMessageNum}
            />
            <OnboardingNativeLanguage
              languageId={this.props.nativeLanguageId}
              show={this.props.show === 'nativeLanguageId'}
              waiting={this.props.seen.indexOf('nativeLanguageId') !== -1}
              seen={this.props.seen.indexOf('nativeLanguageId') !== -1}
              onSubmitNativeLanguageId={this.props.onSubmitNativeLanguageId}
              afterSettingLanguage={this.props.afterSettingLanguage}
              languagesMessageNum={this.props.languagesMessageNum}
            />
            <OnboardingIntro
              showSpinner={this.props.showSpinner}
              show={this.props.show === 'intro'}
              waiting={this.props.seen.indexOf('intro') !== -1}
              seen={this.props.seen.indexOf('intro') !== -1}
              onSubmitIntro={this.props.onSubmitIntro}
            />
            <OnboardingReferrer
              show={this.props.show === 'referrer'}
              waiting={this.props.seen.indexOf('referrer') !== -1}
              seen={this.props.seen.indexOf('referrer') !== -1}
              onSubmitReferrer={this.props.onSubmitReferrer}
              referrerOptions={this.props.referrerOptions}
            />
            <OnboardingRegistration
              languageId={this.props.languageId}
              targetLanguageId={this.props.targetLanguageId}
              levelId={this.props.levelId}
              hideSpinner={!this.showSpinner}
              showSpinner={this.props.showSpinner}
              show={this.props.show === 'registration'}
              waiting={this.props.seen.indexOf('registration') !== -1}
              seen={this.props.seen.indexOf('registration') !== -1}
              onSkipRegistration={this.props.onSkipRegistration}
              afterRegistration={this.props.afterRegistration}
              hideGuestSignIn={this.props.hideGuestSignIn}
              authType={this.props.showSignIn || !AppUI.siteIsDefault ? 'signIn' : 'registration'}
            />
            {
              !this.props.showSpinner &&
                <OnboardingChooseDeck
                  show={this.props.show === 'chooseDeck'}
                  waiting={this.props.seen.indexOf('chooseDeck') !== -1}
                  seen={this.props.seen.indexOf('chooseDeck') !== -1}
                  onSelectLearningPreference={this.props.onSelectLearningPreference}
                />
            }
          </OnboardingInner>
        </Onboarding>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(OnboardingWeb);
