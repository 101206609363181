"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
// this is ordered by name

var Languages = {
  AB: 'Abkhazian',
  AA: 'Afar',
  AF: 'Afrikaans',
  SQ: 'Albanian',
  AM: 'Amharic',
  AR: 'Arabic',
  HY: 'Armenian',
  AS: 'Assamese',
  AY: 'Aymara',
  AZ: 'Azerbaijani',
  BA: 'Bashkir',
  EU: 'Basque',
  BN: 'Bengali',
  DZ: 'Bhutani',
  BH: 'Bihari',
  BI: 'Bislama',
  BS: 'Bosnian',
  BR: 'Breton',
  BG: 'Bulgarian',
  MY: 'Burmese',
  BE: 'Belarusian',
  KM: 'Cambodian',
  CA: 'Catalan',
  ZH: 'Chinese (Simplified)',
  ZH2: 'Chinese (Traditional)',
  CO: 'Corsican',
  HR: 'Croatian',
  CS: 'Czech',
  DA: 'Danish',
  NL: 'Dutch',
  EN: 'English (American)',
  EN2: 'English (British)',
  EO: 'Esperanto',
  ET: 'Estonian',
  FO: 'Faeroese',
  FJ: 'Fiji',
  FI: 'Finnish',
  FR: 'French',
  FY: 'Frisian',
  GD: 'Gaelic',
  GL: 'Galician',
  KA: 'Georgian',
  DE: 'German',
  EL: 'Greek',
  KL: 'Greenlandic',
  GN: 'Guarani',
  GU: 'Gujarati',
  HA: 'Hausa',
  IW: 'Hebrew',
  HI: 'Hindi',
  HU: 'Hungarian',
  IS: 'Icelandic',
  IN: 'Indonesian',
  IK: 'Inupiak',
  GA: 'Irish',
  IT: 'Italian',
  JA: 'Japanese',
  JW: 'Javanese',
  KN: 'Kannada',
  KS: 'Kashmiri',
  KK: 'Qazaq',
  RW: 'Kinyarwanda',
  KY: 'Kirghiz',
  RN: 'Kirundi',
  KO: 'Korean',
  KU: 'Kurdish',
  LO: 'Laothian',
  LA: 'Latin',
  LV: 'Latvian',
  LN: 'Lingala',
  LT: 'Lithuanian',
  MK: 'Macedonian',
  MG: 'Malagasy',
  MS: 'Malay',
  ML: 'Malayalam',
  MT: 'Maltese',
  MI: 'Maori',
  MR: 'Marathi',
  MN: 'Mongolian',
  CN: 'Montenegrin',
  NA: 'Nauru',
  NE: 'Nepali',
  NO: 'Norwegian',
  OC: 'Occitan',
  OR: 'Oriya',
  OM: 'Oromo',
  PS: 'Pashto',
  FA: 'Persian',
  PL: 'Polish',
  PT: 'Portuguese (European)',
  PT2: 'Portuguese (Brazilian)',
  PA: 'Punjabi',
  QU: 'Quechua',
  RM: 'Rhaeto',
  RO: 'Romanian',
  RU: 'Russian',
  SM: 'Samoan',
  SG: 'Sangro',
  SA: 'Sanskrit',
  SR: 'Serbian',
  ST: 'Sesotho',
  TN: 'Setswana',
  SN: 'Shona',
  SD: 'Sindhi',
  SI: 'Sinhala',
  SS: 'Siswati',
  SK: 'Slovak',
  SL: 'Slovenian',
  SO: 'Somali',
  ES: 'Spanish',
  SU: 'Sudanese',
  SW: 'Swahili',
  SV: 'Swedish',
  TL: 'Tagalog',
  TG: 'Tajik',
  TA: 'Tamil',
  TT: 'Tatar',
  TE: 'Telugu',
  TH: 'Thai',
  BO: 'Tibetan',
  TI: 'Tigrinya',
  TO: 'Tonga',
  TS: 'Tsonga',
  TR: 'Turkish',
  TK: 'Turkmen',
  TW: 'Twi',
  UK: 'Ukrainian',
  UR: 'Urdu',
  UZ: 'Uzbek',
  VI: 'Vietnamese',
  VO: 'Volapuk',
  CY: 'Welsh',
  WO: 'Wolof',
  XH: 'Xhosa',
  JI: 'Yiddish',
  YO: 'Yoruba',
  ZU: 'Zulu'
};
var _default = Languages;
exports["default"] = _default;