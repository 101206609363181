import React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import { CardTypeStore, WordTypeStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';

@observer
class CardTypeEdit extends React.Component {

  constructor(props) {
    super(props);
    this.getCardType();
    WordTypeStore.getIndex({ filters: {root: true}});
  }

  componentWillUnmount() {
    CardTypeStore.clearShowData();
  }

  @computed get wordTypeOptions() {
    if (WordTypeStore.hasIndexData) {
      return WordTypeStore.indexData.map(item => [item.id, item.name]);
    }
    return [];
  }

  @autobind getCardType() {
    CardTypeStore.getShow({ ids: {cardTypeId: this.props.params.cardTypeId }}, this.afterCardTypeGetShow);
  }

  @autobind afterCardTypeGetShow(resp) {
    CardTypeStore.setShowData(resp);
  }

  render() {
    return (
      <div className='card-type-edit'>
        <div className="row">
          <div className="col-xs-12">
            <fieldset>
              <legend>Name</legend>
              <InPlaceText
                model="card_types"
                field="name"
                defaultValue={CardTypeStore.showDataField('name')}
                id={CardTypeStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <fieldset>
              <legend>Word Type</legend>
              <InPlaceSelect
                includeBlank
                model="card_types"
                field="word_type_id"
                value={CardTypeStore.hasShowData ? CardTypeStore.showData.wordType?.id : null}
                id={CardTypeStore.showDataField('id')}
                options={this.wordTypeOptions}
                afterChange={this.getCardType}
              />
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <fieldset>
              <legend>Slug</legend>
              <InPlaceText
                model="card_types"
                field="slug"
                defaultValue={CardTypeStore.showDataField('slug')}
                id={CardTypeStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <fieldset>
              <legend>Description</legend>
              <InPlaceText
                newLineToBr
                model="card_types"
                field="description"
                inputType="textarea"
                defaultValue={CardTypeStore.showDataField('description')}
                id={CardTypeStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default CardTypeEdit;
