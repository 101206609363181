import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  width: 70px;
  height: 18px;
  text-align: center;

  & > div {
    width: 18px;
    height: 18px;
    background: ${(props) => props.background};
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
    animation: bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0.0) }
    40% { -webkit-transform: scale(1.0) }
  }

  @keyframes bouncedelay {
    0%, 80%, 100% {
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 40% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
`;

class Spinner extends React.Component {

  static defaultProps = {
    background: Theme.blue,
    margin: "30px auto 0",
  }

  render() {
    return (
      <Wrapper
        background={this.props.background}
        margin={this.props.margin}
      >
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </Wrapper>
    );
  }
}

export default Spinner;
