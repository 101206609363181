import React from 'react';
import { observer } from 'mobx-react';
import Text from 'components/text';
import { flexCenterColumn, flexCenter } from '@seedlang/style_mixins';
import LanguageOption from 'components/language_option';
import styled from '@emotion/styled';
import SlideWrapper from 'components/hoc/slide_wrapper.js';

const Wrapper = styled.div`
  width: 100%;
  ${flexCenterColumn()}
  height: 100%;
`;

const Content = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  flex: 1;
`;

const OptionsWrapper = styled.div`
  ${flexCenter()}
`;

@observer
class OnboardingNativeLanguage extends React.Component {

  render() {
    return (
      <Wrapper>
        <Content>
          <Text
            center
            bold
            fontSize="19px"
            margin="0 0 20px 0"
            color="#FFF"
          >
            What language would you like to learn?
          </Text>
          <OptionsWrapper>
            {
              ['DE', 'ES', 'FR'].map(item => {
                return (
                  <LanguageOption 
                    onClick={() => this.props.onSubmitTargetLanguageId(item)}
                    key={item}
                    languageId={item}
                  />
                )
              })
            }
          </OptionsWrapper>
        </Content>
      </Wrapper>
    );
  }
}

export default SlideWrapper(OnboardingNativeLanguage);
