import { AppUI } from '@seedlang/state';
import { AuthenticateStore } from '@seedlang/stores';
import { loadScript } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

// https://developer.apple.com/documentation/sign_in_with_apple/displaying_sign_in_with_apple_buttons_on_the_web 

const ButtonDiv = styled.div`
  width: 100%;
  height: 50px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(${(props) => props.scaleOnHover});
  }
  svg {
    fill: ${Theme.blue};
  }
`

@observer
class AppleSignInButton extends React.Component {
  componentDidMount() {
    this.initAppleSignIn();
  }

  @autobind initAppleSignIn() {
    loadScript("https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js").then(() => {
      setTimeout(this.adjustButtonStyle, 1)

      window.AppleID.auth.init({
        clientId: 'com.seedlang.web.main',
        scope: 'name email',
        redirectURI: `https://${AppUI.site.domainString}/apple/callback/not_in_use`,
        state: '',
        nonce: '',
        usePopup: true
      });

      document.addEventListener('AppleIDSignInOnSuccess', this.onAppleSignInSuccess);
    })
  }

  @autobind onAppleSignInSuccess(event) {
    AuthenticateStore.signInWithAppleAuthorization({
      data: {
        authorization: event.detail.authorization,
        assign_membership_group_id: AppUI.getCookieValue('assign_membership_group_id'),
        concept_id: this.props.conceptId,
      }
    }, this.props.afterSignIn);
  }

  @autobind adjustButtonStyle() {
    const buttonElement = document.querySelector('#appleid-signin > *[role=button]')
    if (buttonElement) {
      buttonElement.style.maxWidth = null;
    }
  }

  render() {
    return (
      <ButtonDiv
        id="appleid-signin"
        data-color="white"
        data-border="false"
        data-border-radius="50"
        data-type="sign in"
        mode="center-align"
        scaleOnHover={this.props.scaleOnHover}
      />
    );
  }
}

export default AppleSignInButton;
