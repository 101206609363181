import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ListSelect from 'components/list_select';
import Text from 'components/text';
import styled from '@emotion/styled';
import SlideWrapper from 'components/hoc/slide_wrapper.js';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import { shuffle } from 'lodash';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  width: 100%;
  ${flexCenterColumn()}
  height: 100%;
`;

const Content = styled.div`
  ${flexCenterColumn()}
  width: 100%;
`;

const Button = styled.div`
  background: ${props => props.background};
  color: ${props => props.color};
  width: 100%;
  border-radius: 30px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  padding: 10px 0;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #FFF;
    color: ${Theme.blue};
    transform: scale(1.05);
  }
`;

@observer
class OnboardingReferrer extends React.Component {

  @computed get otherId() {
    return isPresent(this.props.referrerOptions) && this.props.referrerOptions.find(item => item.text === 'Other').id;
  }

  render() {
    return (
      <Wrapper>
        <Content>
          <Text
            center
            heading="3"
            margin="0 0 20px 0"
            color="#FFF"
          >
            How did you hear about us?
          </Text>
          <ListSelect
            options={shuffle(this.props.referrerOptions.filter(item => item.text !== 'Other'))}
            onSelect={this.props.onSubmitReferrer}
            fieldName="text"
          />
          <Button
            background="#FFF"
            color={Theme.blue}
            onClick={() => this.props.onSubmitReferrer(this.otherId)}
          >
            Other
          </Button>
          <Button
            background="#8D8D8D"
            color="#FFF"
            onClick={() => this.props.onSubmitReferrer(null)}
          >
            Skip
          </Button>
        </Content>
      </Wrapper>
    );
  }
}

export default SlideWrapper(OnboardingReferrer);
