import React from 'react';
import { AppUI } from '@seedlang/state';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import { Link } from 'react-router';
import styled from '@emotion/styled';

const Alert = styled.div`
  border: 2px solid ${Theme.orange};
  padding: 10px;
  border-radius: 5px;
  color: #333;
  margin: 20px 0;
  text-align: center;
`;

const SubText = styled.div`
  font-size: 12px;
  line-height: 14px;
`;

class AlreadyExistsNotice extends React.Component {
  render() {
    return (
      <>
        <Alert>
          <b>An account already exists with that email address.</b>
          {
            !AppUI.siteIsDefault &&
              <SubText>
                Note that this could be a Seedlang account or<br />another Easy Languages account.
              </SubText>
          }
        </Alert>

        {AppUI.siteIsDefault ? (
          <Button
            height="50px"
            width="100%"
            borderRadius="50px"
            background="#FFF"
            backgroundOnHover="#FFF"
            onClick={this.props.onClickSignIn}
            color={Theme.blue}
          >
            Sign In
          </Button>
        ) : (
          <Link
            to={{ name: "onboarding.sign_in" }}
          >
            <Button
              height="50px"
              width="100%"
              borderRadius="50px"
              background={AppUI.site.accentColor}
              backgroundOnHover={AppUI.site.accentColor}
              color="#FFF"
            >
              Sign In
            </Button>
          </Link>
        )}
      </>
    );
  }
}

export default AlreadyExistsNotice;
