"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = formatNumber;
var _is_present = _interopRequireDefault(require("./is_present"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function formatNumber(number) {
  if ((0, _is_present["default"])(number)) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}